@import "./src/styles/variables";

.report-data-wrapper {
  height: 100%;
  background: $mainSkyBlue;
}

.report-header {
  padding: 5px 10px;
  &:after{
    content: "";
    display: table;
    clear: both;
  }
  .float-left {
    float: left;
  }
  .float-right {
    float: right;
  }
  .title {
    font-size: 20px;
  }
}

.report-error {
  background: $mainWhite;
  border-radius: 5px;
  padding: 20px 20px 20px 20px;
  margin-left: 20px;
  .message {
    color: $mainGreyText;
    font-size: 12px;
    font-weight: 100;
    letter-spacing: .9px;
    margin-bottom: 45px;
  }
}

.export {
  position: absolute;
  right: 0;
  top: -5px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #039be5;
  line-height: 60px;
  .export-link {
    margin-left: auto;
  }

  .table-settings__control-link {
    cursor: pointer;
  }

  .table-settings__control-icon {
    padding: 0 3px;
    vertical-align: middle;
  }
}