@import "src/styles/variables";

@mixin active_tab {
  color: #fff !important;
  border: none;
  border-bottom: 4px solid $mainLightBlue;
}

.edb-tabset {
  &__tabs {
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: #333333;
    list-style: none;

    &--my-team, &--notification-center {
      background-color: $mainDarkBlue;
    }

    &--notification-popup {
      background-color: $mainDarkBlue;
      margin-top: 15px;
    }

    &:before, &:after {
      line-height: 0;
      display: table;
      content: "";
    }

    &:after {
      clear: both;
    }
  }

  &__tab-title {
    float: left;
    width: 150px;
    text-align: center;

    background-color: #e4e4e4;

    &--my-team, &--notification-center {
      width: 260px;
      margin: 0 10px;
      background-color: $mainDarkBlue;
    }

    &--notification-popup {
      width: $notificationWidth/2;
      background-color: $mainDarkBlue;
    }
  }

  &__tab-link {
    position: relative;
    display: block;
    cursor: pointer;
    padding: 14px 0;
    border: 1px solid #c6c6c6;
    border-radius: 4px 4px 0 0;

    &--my-team, &--notification-center {
      border: none;
      color: #768891 !important;
      letter-spacing: 0.9px;
      font-size: 14px;
    }

    &--my-team-active, &--notification-center-active {
      @include active_tab;
    }

    &--notification-popup {
      border: none;
      padding-bottom: 8px;
      color: $mainGreyText;
    }

    &--notification-popup-active {
      padding-bottom: 8px;
      @include active_tab;
    }

    &--active {
      cursor: default;
      background-color: #fff;
      border-bottom: 1px solid #fff;
    }

    &:hover, &:active, &--active {

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
      }
    }

    &--greyout {
      color: rgba(118, 136, 145, 0.5) !important;
      cursor: default !important;
      background-color: inherit !important;
      &:before {
        content: none !important;
      }
    }
  }
}

.my-team-leaves {
  display: inline-block;
  margin: 20px 25px;
  text-decoration: none;
  color: #039be5;
  font-size: 18px;
  font-weight: 600;
}

.tab-panel-leaves, .tab-panel-team-info {
  display: none;
}

.active-panel {
  display: block;
}