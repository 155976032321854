@import "./src/styles/variables";
@import "./src/styles/mixins";

$searchInputSize: 220px;
$singleResultHeight: 42px;
$quickSearchAvatarHeight: 26px;

.quick-search {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  padding: 0 0;
  input[type="text"] {
    width: 36px;
    height: 36px;
    margin: 0 10px;
    border: none;
    border-bottom: 1px solid #fff;
    padding-top: 12px;
    padding-left: 10px;
    background: url("./../../../assets/images/navbar/new-search.png") no-repeat right center;
    color: transparent;
    font-size: 18px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
    outline: 0;
    cursor: pointer;
    &:-moz-placeholder {
      color: transparent;
    }
    &::-webkit-input-placeholder {
      color: transparent;
    }
    &:focus:-moz-placeholder {
      color: #ccc;
    }
    &:focus::-webkit-input-placeholder {
      color: #ccc;
    }
  }
  &.active {
    background-color: #ebf2f5;
    input[type="text"] {
      width: $searchInputSize;
      color: $mainLightDarkText;
      letter-spacing: 0.07px;
      border-bottom: 1px solid $mainGreyText;
    }
    &::before {
      content: "";
      display: inline-block;
      width: 100%;
      height: 4px;
      position: absolute;
      top: 0;
      background: $mainDarkBlue;
    }
  }
  &--results {
    position: absolute;
    top: $navbarHeight;
    right: 0;
    z-index: 1000;
    width: $searchInputSize + 20;
    min-height: 60px;
    max-height: 380px;
    font-size: 13px;
    letter-spacing: 0.07;
    color: $mainLightDarkText;
    background-color: #FFF;
    @include popup-box-shadow;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow-y: auto;
    &_item {
      &_title {
        margin: 0;
        padding: 5px 15px;
        border-bottom: 1px solid #f1f1f3;
        font-size: 12px;
        font-weight: bold;
        color: #9DA2A5;
      }
      &_content {
        margin-bottom: 2px;
        &_person {
          height: $singleResultHeight;
          padding-top: ($singleResultHeight - $quickSearchAvatarHeight) / 2;
          padding-left: 15px;
          margin: 0;
          text-align: left;
          color: $mainGreyText;
          cursor: auto;
          > a {
            display: block;
            width: 100%;
            padding-top: 4px;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-decoration: none;
            cursor: pointer;
            color: $mainLightBlue;
            > img {
              width: 26px;
              height: 26px;
              margin-right: 8px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          &:hover {
            background: $mainSoftBlue;
          }
        }
      }
    }
    &_no-results {
      margin: 20px auto;
      padding-right: 15px;
      text-align: center;
      font-size: 14px;
      color: $mainGreyText;
    }
  }
}
