@import "./src/styles/variables";

.edb-tenure-wrapper {
  background: $mainSkyBlue;
  padding-bottom: 10px;
  height: 100%;

  .tenure-view {
    background: $mainSkyBlue;
  }

  .clearfloat {
    &:before, &:after {
      content: " ";
      display: table;
    }
    &:after {
      clear: both;
    }
  }
  .fa-calendar-alt {
    top: 8px;
    right: 8px;
  }
}

.tenure-filter-form {
  padding: 30px 32px;
  .info {
    width: 110px;
  }

  .filter-field {
    > select {
      width: 160px;
      padding: 5px;
      font-size: 13px;
    }
    .rbt {
      .rbt-input-main {
        width: 160px;
        height: 32px;
      }
      .dropdown-menu {
        .dropdown-item {
          font-size: 13px;
        }
      }
    }
    .react-datepicker-wrapper {
      input.ui-datepicker {
        width: 160px;
        height: 32px;
      }
    }
  }

  .tenure-radio-group {
    margin-top: 26px;
    input[type="radio"] {
      display: none;
    }
    label {
      border: 1px solid $mainLightBlue;
      background-color: $mainWhite;
      color: $mainLightBlue;
      width: 86px;
      height: 32px;
      line-height: 32px;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.75px;
      &:hover {
        cursor: pointer;
      }
      &.first {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        margin-right: -4px;
      }
      &.last {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
    input[type="radio"]:checked + label {
      background-color: $mainLightBlue;
      color: $mainWhiteText;
    }
  }

  .ui-datepicker {
    input {
      margin: 0;
    }
    ul {
      left: unset !important;
      right: 0;
    }
  }

  .clear-button {
    color: #000000a1;
  }

  .control-buttons {
    button {
      margin-top: 17px;
    }
  }

  .export-link {
    line-height: 40px !important;
    > .table-settings__control-link {
      margin-left: 30px !important;
      margin-right: 0 !important;
    }
  }

  .edb-label {
    font-size: 12px;
    color: #829199;
    font-weight: 400;
    display: block;
    text-align: left;
  }
}
.tenure-table {
  &__wrapper {
    height: calc(100vh - 180px);
    padding: 16px;
  }
  &__data {
    overflow-y: auto;
    scrollbar-width: thin;

    .table {
      thead {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background: $mainSkyDarkBlue;
        color: $mainGreyText;
        letter-spacing: .483px;
        text-transform: uppercase;
        tr {
          th {
            position: sticky;
            top: -2px;
            height: 64px;
            padding: 15px;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1px;
            text-align: left;
            vertical-align: middle;
            white-space: nowrap;
            background: $mainSkyDarkBlue;
            &:last-child {
              text-align: right;
            }
            span {
              vertical-align: center;
            }
          }
        }
      }
      tbody {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        font-size: 14px;
        tr {
          &:nth-child(even) {
            background: $mainSoftBlue;
          }
          &:nth-child(odd) {
            background: $mainWhite;
          }
          a {
            cursor: pointer;
            color: $mainLightBlue;
            word-break: break-all;
          }
          td {
            height: 51px;
            padding: 15px;
            vertical-align: middle;
            text-align: left;
            white-space: nowrap;
            p {
              margin: 0 !important;
            }
            &:last-child {
              p {
                text-align: right;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}
.font-size {
  &--12 {
    font-size: 12px !important;
  }
  &--14 {
    font-size: 14px !important;
  }
  &--18 {
    font-size: 18px !important;
  }
}

.font-weight--600 {
  font-weight: 600 !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.color--azure {
  color: $mainLightBlue !important;
}


