@import '../../styles/variables';

.react-datepicker-wrapper {
  display: block !important;
  * {
    color: $mainDarkGreyText;
  }
  .react-datepicker__close-icon {
    right: 22px;
    &::after {
      background-color: $mainDarkGreyText;
    }
  }
  .form-control {
    &[disabled] {
      color: $mainTextInputDisabled;
      opacity: .6;
      background-color: $mainTextInputBackgroundDisabled;
      border-color: $mainTextInputBorderDisabled;
    }
  }
  input {
    &.date-picker,
    &.input-group {
      outline: none;
      background: url('data:image/svg+xml,<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 2H15V1C15 0.45 14.55 0 14 0C13.45 0 13 0.45 13 1V2H5V1C5 0.45 4.55 0 4 0C3.45 0 3 0.45 3 1V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 17C16 17.55 15.55 18 15 18H3C2.45 18 2 17.55 2 17V7H16V17ZM4 9H6V11H4V9ZM8 9H10V11H8V9ZM12 9H14V11H12V9Z" fill="%23A6B7C2"></path></svg>') #FFFFFF no-repeat right 6px center;
      background-size: 18px;
      cursor: pointer;
    }
    &.input-group {
      width: 130px;
      height: 32px;
      border: 1px solid #d2deea;
      padding: 5px;
      border-radius: 2px;
    }
  }
}
.react-datepicker {
  color: $mainDarkGreyText;

  &__input-container {
    > input {
      border: 1px solid #d2deea;
    }
  }

  &__header {
    border-width: 0 !important;
    background-color: $mainWhite !important;
  }

  &__month {
    margin: 0 0.4rem;
    &-select {
      border-width: 0 !important;
      outline: none !important;
      color: #647483 !important;
    }
  }

  &__year {
    &-select {
      border-width: 0 !important;
      outline: none !important;
      color: #647483 !important;
    }
  }

  &__day {
    width: 34px !important;
    margin: 0 !important;
    border: 1px solid #d2deea;
    border-radius: 3px;
    padding: 5px 10px;
    background-color: $mainWhite;
    outline-color: #d2deea;
    color: $mainDarkGreyText !important;
    font-size: 12px;
    line-height: 18px !important;
    &:hover {
      background-color: #e6e6e6;
      border-color: #adadad;
    }
    &-name {
      width: 34px !important;
      margin: 0 !important;
      padding: 5px 10px;
      font-size: 12px;
      line-height: 18px !important;
    }
    &--today {
      font-weight: normal !important;
      background-color: $mainWhite;
      color: #17A2B8 !important;
    }
    &--keyboard-selected {
      background-color: $mainWhite !important;
      //border-color: $mainWhite !important;
      //color: #117a8b !important;
    }
    &--selected {
      background-color: #117a8b !important;
      border-color: #117a8b !important;
      color: $mainWhite !important;
    }
    &--disabled {
      cursor: default;
      color: #cccccc !important;
    }
  }

  .date-picker--footer {
    button {
      line-height: 18px;
      font-size: 12px;
    }
  }
}
.react-datepicker-popper {
  z-index: 10 !important;
}