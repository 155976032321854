@import './src/styles/variables';

.headcount-report {
  padding: 0 10px 20px;
  background-color: #ebf2f5;
  color: #313942;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  > div {
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  .collapse-toggle {
    padding-left: 15px !important;
    margin-left: -15px !important;
    .fa-caret-down,
    .fa-caret-right {
      left: 0;
      transition: 0.5s ease-in-out;
      &.top-3 {
        top: 3px !important;
      }
      &.top-5 {
        top: 5px !important;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }

  &--header {
    width: 100%;
    padding: 15px 10px 15px 30px !important;
    background-color: #ebf2f5;
    &-title {
      align-items: flex-end;
      font-weight: 600;
      div {
        font-size: 14px;
        > .row {
          padding: 0 10px !important;
          > .col {
            padding: 5px 30px;
            font-size: 14px;
            text-align: center;
            background-color: #FFFFFF;
          }
        }
        &:first-child {
          padding-top: 5px;
          padding-bottom: 5px;
          font-size: 18px;
        }
      }
    }
    &-data {
      font-size: 14px;
      font-weight: 600;
      div {
        > .row {
          padding: 0 10px !important;
          > .col {
            padding: 5px 30px;
            font-size: 14px;
            text-align: right;
            background-color: #FFFFFF;
          }
        }
      }
    }
    &-subtitle {
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #647483 !important;
      div {
        > .row {
          padding: 0 10px !important;
          > .col {
            padding: 5px 30px 10px 5px;
            text-align: right;
            background-color: #FFFFFF;
          }
        }
        &:first-child {
          padding: 10px 15px;
        }
      }
    }
  }

  &--body {
    padding-right: 5px;
    background-color: #ebf2f5;
  }

  &-row--location,
  &-row--org-structure,
  &-row--department,
  &-row--unit,
  &-row--team {
    align-items: center;
    padding: 10px 10px 10px 30px !important;
    div {
      font-size: 14px;
      > .row {
        padding: 0 10px !important;
        > .col {
          padding: 0 30px;
          font-size: 14px;
          text-align: right;
        }
      }
    }
  }

  &-row--location {
    font-weight: 600;
    background-color: #FFFFFF;
    p {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &-row--org-structure {
    font-weight: 600;
    background-color: #ebf2f5;
  }

  &-row--department,
  &-row--unit,
  &-row--team {
    background-color: #FFFFFF;
  }

  .font-size {
    &--12 {
      font-size: 12px !important;
    }
    &--14 {
      font-size: 14px !important;
    }
    &--18 {
      font-size: 18px !important;
    }
  }

  .card-body {
    min-height: unset;
  }

  a {
    color: #039be5 !important;
  }
}

