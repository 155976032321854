.horizontal-bar-wrapper {
  margin-top: 20px;
  margin-bottom: 30px;
  .horizontal-bar {
    display: inline-block;
    width: 60%;
    min-width: 100px;
    height: 12px;
    background-color: #ccd3df;
    margin-top: 8px;
    margin-right: 5px;
    cursor: pointer;
    .bar-overlay {
      height: 12px;
      display: block;
      &.billable {
        background-color: #57d07d;
      }
      &.not_billable {
        background-color: #ffae00;
      }
      &.joined {
        background-color: #55bbcc;
      }
      &.left {
        background-color: #eb430f;
      }
    }
    .count {
      display: inline-block;
    }
  }
}