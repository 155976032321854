.notification-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  overflow-y: unset;
  text-align: center;
  padding-top: 23%;
  &--img {
    width: 140px;
    height: 140px;
    background: url("./../../../../assets/images/no-notifications.png") no-repeat center center;
    background-size: cover;
  }
  &--p {
    font-size: 18px;
    color: #a4b5bd;
  }
}