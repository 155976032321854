@import "./src/styles/variables";

.feedback-item {
  display: grid;
  grid-template-columns: auto 40px;
  padding: 15px;
  font-size: 14px;
  font-weight: 400;
  .info {
    .feedback-item-attribute {
      font-weight: 400;
      &__label {
        color: $mainGreyText;
        font-size: 12px;
        letter-spacing: .06px;
        margin-bottom: 5px;
      }
      &__value {
        color: $mainLightDarkText;
        font-size: 14px;
        letter-spacing: .07px;
        margin-bottom: 5px;
        > a {
          text-decoration: none;
          color: $mainLightBlue;
        }
      }
    }
    .feedback-value {
      padding: 15px 0 0 0;
      color: $mainLightDarkText;
      font-size: 14px;
      white-space: break-spaces;
      word-break: break-word;
    }
  }
  .actions {
    padding: 0 13px;
    text-align: center;
    svg {
      font-size: 12px;
      color: $mainLightText;
      cursor: pointer;
    }
  }
}
