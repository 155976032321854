@import './src/styles/variables';

.rbt {
  .rbt-input-main {
    width: 170px;
    padding-right: 45px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.1251 1.00002L6.0051 4.88002L9.8851 1.00002C10.2751 0.61002 10.9051 0.61002 11.2951 1.00002C11.6851 1.39002 11.6851 2.02002 11.2951 2.41002L6.7051 7.00002C6.3151 7.39002 5.6851 7.39002 5.2951 7.00002L0.705098 2.41002C0.315098 2.02002 0.315098 1.39002 0.705098 1.00002C1.0951 0.62002 1.7351 0.61002 2.1251 1.00002Z' fill='%23A6B7C2'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center right 12px;
  }
  .dropdown-menu {
    padding: 0;
    scrollbar-width: thin;
    font-family: 'Source Sans Pro';
    .dropdown-header {
      padding: 2px 8px;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      color: $mainLightGreyText;
    }
    .dropdown-item {
      padding: 5px 13px;
      font-size: 16px;
      line-height: 14px;
      color: #303030;
      white-space: break-spaces;
      outline: none;
      &:active {
        background: inherit;
      }
      mark {
        padding: 0;
        background: transparent;
      }
    }
    &::-webkit-scrollbar {
      max-width: 10px;
    }
  }
  .rbt-aux {
    position: absolute;
    right: 27px;
    bottom: 0;
    height: 100%;
    .close {
      height: 100%;
      color: #A6B7C2;
    }
  }
}
