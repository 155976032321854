@import "./src/styles/variables";

.headcount-dynamic-error {
  background: $mainWhite;
  border-radius: 5px;
  padding: 20px 20px 20px 20px;
  margin-left: 20px;
  .header {
    color: $mainGreyText;
    font-size: 12px;
    font-weight: 100;
    letter-spacing: .9px;
    margin-bottom: 45px;
  }
}

.data-wrapper {
  overflow-y: auto;
  .sticky-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 0 15px;
  }
  .details-control {
    position: relative;
    height: 55px;
    background: #fff;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    // border-bottom: 1px solid $mainSkyBlue;
    .tabs {
      list-style: none;
      padding-left: 20px;
      .tab {
        display: inline-block;
        margin-right: 32px;
        height: 54px;
        font-family: 'Source Sans Pro';
        font-size: 12px;
        line-height: 50px;
        color: $mainWhiteGrey;
        text-transform: uppercase;
        border-bottom-width: 4px;
        border-bottom-style: solid;
        border-bottom-color: $mainWhite;
        &.active {
          color: $mainDarkBlue;
          border-bottom-color: $mainLightBlue;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .markers-wrapper {
      position: absolute;
      width: 320px;
      right: 30px;
      margin-top: 20px;
      & > .marker {
        position: relative;
        display: inline-block;
        margin-right: 30px;
        &::before {
          content: '';
          position: absolute;
          left: -20px;
          top: 50%;
          transform: translateY(-50%);
          width: 12px;
          height: 12px;
          border-radius: 4px;
        }
        &.non-billable::before{
          background-color: #ffae00;
        }
        &.billable::before{
          background-color: #57d07d;
        }
        &.joined::before{
          background-color: #55bbcc;
        }
        &.left::before{
          background-color: #eb430f;
        }
      }
    }
    .filter-field {
      margin-right: 20px;
      padding-top: 3px;
    }
  }
}

.headcount-dynamic-control {
  background: $mainWhite;
  padding: 10px 20px 15px 10px;
  .country-wrapper {
    display: flex;
    align-items: center;
    .rbt {
      .rbt-input-main {
        width: 160px;
        height: 32px;
      }
      .dropdown-menu {
        .dropdown-item {
          font-size: 13px;
        }
      }
    }
    .selectize-input {
      font-size: 14px;
    }
    .custom-label {
      margin-top: 0;
    }
  }
  .custom-label {
    color: #959595;
    margin-right: 10px;
    margin-top: 5px;
  }
  .filter-group {
    display: flex;
    padding-left: 5px;
    & > div {
      margin-top: 20px;
    }
  }
  .buttons-list {
    margin-right: 20px;
    .button {
      display: inline-block;
      font-family: 'Source Sans Pro';
      font-size: 12px;
      line-height: 28px;
      text-align: center;
      height: 30px;
      width: 80px;
      border: 1px solid #768691;
      margin-left: -1px;
      color: #768691;
      text-transform: capitalize;
      &.btn-small {
        width: 55px;
      }
      &.active {
        color: $mainWhite;
        background: #768691;
      }

      &:hover {
        cursor: pointer;
      }

      &:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      &:last-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
    @media (max-width: 1200px) {
      margin-bottom: 10px;
    }
  }

  .range-picker {
    margin-right: 20px;
    height: 30px;
    display: inline-block;
    .range-input {
      display: inline-block;
    }
  }

  .export-wrapper {
    color: $mainLightBlue;
  }
}

