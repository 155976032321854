@import './src/styles/variables';
@import './src/styles/mixins';

#activateWorkRecord {
  @include popupHeader();
  width: 570px;
  height: auto;

  .info {
    color: $mainLightDarkText;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .084px;
    margin-bottom: 31px;
  }

  .form-activate-wr {
    padding: 20px;
  }

  .edb-employ-form-wrapper {
    margin: auto;
    width: 368px;
    padding: 0;
  }

  .form-activate-wr-header {
    padding: 20px;
    background: $mainPailBlue;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    .main-attribute {
      color: $mainGreyText;
      font-size: 13px;
      font-weight: 600;
    }
    .secondary-attribute {
      color: $mainLightGreyText;
      font-size: 12px;
      font-weight: 400;
      span {
        margin-right: 22px;
      }
    }
  }

  .form-activate-wr-body {
    padding: 20px 20px 10px 20px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid $mainPailBlue;
    .form-control {
      height: 32px;
      font-size: 12px;
    }
  }

  .edb-layoff-empl__footer {
    overflow: hidden;
    height: auto;
    padding: 5px 0;
    .edb-layoff-empl__button {
      display: inline-block;
    }
    .edb-pull-right {
      float: right;
    }
    .edb-margin-right {
      margin-right: 20px;
    }
  }

  .invalid-field {
    border-color: #e9322d !important;
    color: #b94a48;
  }

  .react-datepicker-wrapper + svg {
    top: 6px !important;
  }
}
