$mediumFontSize: 14px;
$mainWhite: white;
$mainWhiteText: white;
$mainLightGreen: #66bb6a;
$mainLightRed: #ff5548;

.switcher {
  width: 65px;
  position: relative;

  &-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__checkbox {
    display: none;
  }

  &__label {
    display: block;
    margin: 2px 0 0 0;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
  }

  &__inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;

    &:before, &:after {
      display: block;
      float: left;
      width: 50%;
      height: 28px;
      padding: 0;
      padding-top: 2px;
      line-height: 24px;
      font-size: $mediumFontSize;
      color: $mainWhiteText;
      font-weight: bold;
      box-sizing: border-box;
    }

    &:before {
      content: attr(data-content-on);
      padding-left: 10px;
      background-color: $mainLightGreen;
    }

    &:after {
      content: attr(data-content-off);
      padding-right: 10px;
      background-color: $mainLightRed;
      text-align: right;
    }
  }

  &__switch {
    display: block;
    width: 24px;
    height: 24px;
    margin: 2px;
    background: $mainWhite;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 37px;
    border-radius: 50%;
    transition: all 0.3s ease-in 0s;
  }

  &__checkbox:checked + .switcher__label {
    .switcher__inner {
      margin-left: 0;
    }
  }

  &__checkbox:checked + .switcher__label {
    .switcher__switch {
      right: 0;
    }
  }
}
