.headcount-dynamic-value-wrapper {
  font-family: "Source Sans Pro";
  .headcount-dynamic-value {
    font-size: 36px;
    font-weight: 600;
    color: #768591;
    margin-bottom: 4px;
    .text {
      font-size: 13px;
    }
  }
  .headcount-dynamic-value-label {
    font-size: 18px;
    font-weight: 600;
  }
  .headcount-dynamic-value-range {
    font-size: 14px;
  }
  .date-wrapper {
    font-size: 14px;
    font-weight: normal;
    color: #768591;
  }
  &.mb {
    margin-bottom: 50px;
  }
}
