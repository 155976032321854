@import './src/styles/variables';

$skillLevelWidth: 80px;
$paddingInNameField: 10px;

.skill-checkbox-field {
  .skill {
    position: relative;
    border-radius: 12px;
    border: 1px solid #adc4da;
    margin-bottom: 13px;
    padding: 4px 0;
    font-size: 14px;
    font-weight: 600;
    color: $mainDarkGreyText;
    &.hidden {
      display: none;
    }
    &.unchecked {
      background-color: $mainWhite !important;
      color: $mainDarkGreyText !important;
      border-color: $mainWhite !important;
      .skill__level {
        display: none;
      }
    }
    &--checked {
      border-width: 1px;
      border-style: solid;
    }
    &--search {
      background-color: $mainSkyBlue;
      border-color: $mainSmoke;
    }
    &--level {
      &-1 {
        background-color: $mainSmoke;
        color: $mainWhiteText;
      }
      &-2 {
        margin-left: $paddingInNameField;
        background-color: $mainSoftBlue;
      }
      &-3 {
        margin-left: $paddingInNameField + 10px;
        background-color: $mainSkyBlue;
        border: 0;
      }
      &-4 {
        margin-left: $paddingInNameField + 20px;
      }
    }
    &__control {
      float: left;
      height: 21px;
      width: 10px;
      text-align: center;
      margin-left: 10px;
      cursor: pointer;
    }
    &__checkbox {
      float: left;
      padding-left: 10px;
      input {
        display: none;
      }
      label {
        vertical-align: middle;
        position: relative;
        top: 2px;
        width: 17px;
        height: 17px;
        margin: 0;
        &::before, &::after {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 17px;
          height: 17px;
          content: '';
        }
        &::after {
          z-index: 1;
          text-align: center;
          cursor: pointer;
          font-size: 20px;
          line-height: 18px;
        }
        &::before {
          background: $mainWhite;
          border: 1px solid $mainSmoke;
          border-radius: 2px;
        }
      }
      input:checked + label {
        &::after {
          content: '✓';
        }
      }
    }
    &__name {
      float: left;
      width: 10%;
      text-align: left;
      white-space: nowrap;
      padding-left: 10px;
      user-select: none;
    }
    &__level {
      float: right;
      padding-right: 10px;
      .level {
        float: left;
        margin-right: 25px;
        &:last-child {
          margin-right: 0;
        }
      }
      input {
        display: none;
      }
      label {
        float: left;
        position: relative;
        top: 2px;
        width: 17px;
        height: 17px;
        margin: 0 10px 0 0;
        cursor: pointer;
        &::before, &::after {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          content: '';
        }
        &::after {
          top: 6px;
          left: 6px;
          z-index: 1;
          text-align: center;
          width: 5px;
          height: 5px;
        }
        &::before {
          background: $mainWhite;
          border: 1px solid $mainSmoke;
        }
      }
      input:checked + label {
        &::after {
          background: #647484;
        }
      }
      .level-name {
        float: left;
      }
    }
  }
}
