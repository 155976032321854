.work-record-education {
  .work-record-main {
    &__header {
      height: 97px;
    }
    &__body {
      .education-list {
        padding-top: 24px;
        &__empty {
          font-size: 14px !important;
        }
      }
    }
  }
}
