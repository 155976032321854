@import "./src/styles/variables";

.edb-hr-dashboard-sidebar {
  height: 100%;
  position: absolute;
  transition: all $sidebarAnimationDuration ease-in-out;
  color: $mainGreyText;
  width: 160px;
  background-color: $mainDarkBlue;

  .sidebar-title {
    text-align: center;
    color: $mainWhiteText;
  }

  hr {
    border-top: 1px solid $mainGrey;
  }

  @mixin activeMenu {
    background: $mainDarkGreyText;
    svg {
      > path {
        fill: $mainWhiteText;
      }
      > g {
        stroke: $mainWhiteText;
      }
    }
    .menu-item__name {
      color: $mainWhiteText;
      margin-left: 10px;
    }
  };

  .menu {
    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;
      line-height: 40px;
      position: relative;
      padding-left: 20px;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      &[disabled="disabled"] {
        cursor: not-allowed;
      }
      &__name {
        color: $mainGreyText;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        margin-left: 10px;
      }
      &:hover {
        @include activeMenu;
      }
    }

    &-item--active {
      @include activeMenu;
      &::before {
        position: absolute;
        right: 0;
        display: block;
        width: 3px;
        height: 100%;
        content: "";
        background-color: $mainLightBlue;
      }
    }
  }
}
