@import "./src/styles/variables";

.turnover-control {
  position: relative;
  background: $mainWhite;
  padding: 5px 18px 10px 18px;
  .country-wrapper {
    display: flex;
    align-items: center;
    .rbt {
      .rbt-input-main {
        width: 160px;
        height: 32px;
      }
      .dropdown-menu {
        .dropdown-item {
          font-size: 13px;
        }
      }
    }
    .selectize-input {
      font-size: 14px;
    }
    .custom-label {
      margin-top: 0;
    }
  }
  .custom-label {
    color: #959595;
    margin-right: 10px;
    margin-top: 5px;
  }
  .filter-group {
    display: flex;
    padding-left: 5px;
    & > div {
      margin-top: 20px;
    }
  }
  .buttons-list {
    margin-right: 20px;
    .button {
      display: inline-block;
      font-family: 'Source Sans Pro';
      font-size: 12px;
      line-height: 28px;
      text-align: center;
      height: 30px;
      width: 80px;
      border: 1px solid $mainGrey2020;
      margin-left: -1px;
      color: #768691;
      text-transform: capitalize;
      &.btn-small {
        width: 55px;
      }
      &.active {
        color: $mainWhite;
        background: $mainGrey2020;
      }

      &:hover {
        cursor: pointer;
      }

      &:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      &:last-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
    @media (max-width: 1200px) {
      margin-bottom: 10px;
    }
  }

  .range-picker {
    margin-right: 20px;
    height: 30px;
    display: inline-block;
    .range-input {
      display: inline-block;
    }
  }
}
