@import './src/styles/variables';
@import './src/styles/mixins';

#document {
  @include popupHeader();
  .modal-header {
    .modal-title {
      font-weight: 600;
    }
  }

  .modal-body {
    padding: 0;
  }
  font-family: "Source Sans Pro", sans-serif;

  .document-content {
    padding: 20px;
    .document-name {
      font-size: 24px;
    }
  }

  .document-footer {
    padding: 20px;
    background: $mainPailBlue;
    text-align: right;
    > button {
      margin-left: 20px;
    }
  }
}