@import './src/styles/variables';

#popover-cvRequest {
  .popover-header {
    font-size: 14px;
    color: $mainLightDarkText;
    text-transform: unset;
  }
  .popover-body {
    width: 300px;
    padding: 20px 20px 20px 0;
    text-align: center;
    .edb-btn {

    }
  }
}
