@import "./src/styles/variables";

.date-filter-wrapper {
  label {
    margin: 0;
    text-transform: none;
  }
  .react-datepicker-wrapper {
    display: block;
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    input {
      width: 100%;
      height: 30px;
    }
  }
  .fa-calendar-alt {
    right: 10px;
  }
  .button {
    display: inline-block;
    font-size: 12px;
    line-height: 28px;
    text-align: center;
    height: 30px;
    width: 80px;
    border: 1px solid $mainGrey2020;
    outline: none;
    &.button-ok {
      color: #fff;
      background:$mainGrey2020;
    }
    &.button-cancel {
      color: $mainGrey2020;
      background: $mainWhite;
    }
  }
}
