@import "./src/styles/variables";

.education-item {
  display: grid;
  grid-template-columns: auto 40px;
  font-size: 14px;
  font-weight: 400;
  .info {
    p {
      margin: 0 0 5px 0;
    }
  }
  .actions {
    padding: 0 13px;
    text-align: center;
    svg {
      font-size: 12px;
      color: $mainLightText;
      cursor: pointer;
    }
  }
}
