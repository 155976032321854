@import "./src/styles/variables";
@import "./src/styles/mixins";

.add-employee-form-wrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &__header {
    height: 80px;
    line-height: 80px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 24px;
    font-weight: bolder;
    color: $mainWhite;
    background-color: $mainDarkBlue;
  }

  &__body {
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 40px;
    padding-bottom: 120px;
    background-color: $mainSoftBlue;
    @include custom-scroll;
  }

  .progress-tabs-column {
    background-color: transparent;

    .progress-tabs {
      .progress-tab {
        width: 195px;
        height: 68px;
        line-height: 26px;
        font-size: 14px;
        font-weight: 600;
        position: relative;
        list-style: none;
        color: #768891;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: -38px;
          z-index: 1;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          box-sizing: content-box !important;
          background: #FFFFFF;
          box-shadow: 0 5px 10px rgba(35, 54, 108, 0.07);
        }

        &:before {
          position: absolute;
          left: -29px;
          z-index: 2;
          color: #0A88EC;
        }

        &:first-child::before {
          content: '1';
        }

        &:nth-child(2)::before {
          content: '2';
        }

        &:last-child::before {
          content: '3';
        }

        &.active {
          color: #1D2B32;
          &:after {
            background: #0A88EC;
            box-shadow: 0 5px 10px rgba(10, 136, 236, 0.24);
          }

          &:before {
            color: #FFFFFF;
          }
        }

        &.filled {

          &:after {
            background: #7BB872;
            box-shadow: 0 5px 10px rgba(123, 184, 114, 0.24);
          }

          &:before {
            content: '\2713' !important;
            left: -30px !important;
            color: #FFFFFF;
          }
        }
      }
    }
  }

  &__content {
    background-color: #FFFFFF;
    box-shadow: 0 5px 10px rgba(35, 54, 108, 0.07);
    border-radius: 6px;

    .add-employee-form {
      &__content {
        padding: 40px;
      }
    }

    .form-page-header {
      padding-bottom: 40px;
      p {
        margin-bottom: 10px;
        line-height: 30px;
        font-size: 24px;
        font-weight: 600;
        color: #1D2B32;
      }
      span {
        line-height: 20px;
        font-size: 16px;
        color: #768891;
      }
    }

    .form-footer {
      position: relative;
      padding: 0 !important;
      background-color: transparent !important;

      &__container {
        position: absolute;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

    .form-control {
      width: 100% !important;
      height: 40px !important;
      border: 1px solid #d2deea !important;
      border-radius: 4px;
      padding: 6px 12px;
      font-size: 16px !important;
      font-weight: 400;
      color: #1D2B32;
    }

    .invalid-field {
      border-color: #e9322d !important;
      color: #b94a48;
      > option {
        color: #303030;
      }
    }
  }
}
