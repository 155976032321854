@import "./src/styles/variables";

.headcount-dynamic-details {
  margin-top: -25px;
  padding: 0 15px;
  .headcount-dynamic-details-container {
    background: $mainWhite;
    border-radius: 5px;
    margin-top: 20px;
  }
  .tab-content {
    display: none;
    &.tab-active {
      display: block;
    }
    .empty {
      padding: 20px;
    }
  }
}

