.unit-tree-preview {
  font-size: 16px;
  position: fixed;
  right: -740px;
  top: 0;
  width: 740px;
  background: #fff;
  height: 100%;
  -webkit-box-shadow: -5px 0px 10px -5px rgba(199,199,199,1);
  -moz-box-shadow: -5px 0px 10px -5px rgba(199,199,199,1);
  box-shadow: -5px 0px 10px -5px rgba(199,199,199,1);

  &.active {
    right: 0;
    -webkit-transition: right 0.5s;
    transition: right 0.5s;
  }

  &--container {
    width: 100% !important;
    height: 100% !important;
    padding: 10px;
  }

  .row:before, .row:after {
    width: 0;
    height: 0;
  }

  .img-close {
    top: 13px;
    left: 16px;
    width: 15px;
    height: 16px;
    cursor: pointer;
  }

  .breadcrumb {
    background-color: transparent !important;
    align-items: baseline;
    &--custom {
      margin: 0 !important;
      padding: 8px 15px 8px 30px;
    }
    .breadcrumb-item {
      font-size: 18px;
      &::before {
        font-size: 18px;
      }
    }
  }

  .col-info {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 20px;
  }

  &--menu {
    align-items: baseline;
  }

  &--header,
  &--body {
    .rounded-circle {
      width: 50px;
      height: 50px;
    }
  }

  &--header {
    padding: 30px 0;
    .list-unstyled {
      margin-bottom: 10px;
      padding: 0 30px;
      .media {
        margin-bottom: 10px;
        .media-body {
          padding: 5px 0;
          > h5 {
            margin-top: 0;
            margin-bottom: 3px;
            line-height: 1.1;
            font-size: 18px;
          }
          > span {
            font-size: 14px;
          }
        }
        >.rounded-circle {
          margin-right: 10px;
        }
      }
    }
  }

  &--body {
    > div {
      max-height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
    ul.list-unstyled {
      padding: 0 30px;
      > li.media {
        margin-bottom: 10px;
        > .media-body {
          padding: 5px 0;
          > h5 {
            margin-top: 0;
            margin-bottom: 3px;
            line-height: 1.1;
            font-size: 14px;
            > strong {
              font-weight: 600;
            }
          }
          > span {
            font-size: 12px;
          }
        }
        > .rounded-circle {
          margin-right: 10px;
        }
      }
    }
  }

  .spinner__transform-wrapper {
    height: 100vh;
    background-color: #fff;
    .spinner__outer {
      margin-top: calc(50vh + 16px);
    }
  }

  .font-weight--600 {
    font-weight: 600 !important;
  }

  .font-size--18 {
    font-size: 18px !important;
  }

  .font-size--14 {
    font-size: 14px !important;
  }

  .font-size--12 {
    font-size: 12px !important;
  }

  .text-charcoal-grey {
    color: #313942 !important;
  }

  .text-battleship-grey {
    color: #647483 !important;
  }

  .text-azure {
    color: #039be5 !important;
  }

  .media-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
  }

  .indicators {
    text-align: right;
    > span {
      margin-bottom: 10px;
      font-size: 12px;
      > strong {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .col-custom {
    width: 50%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 1366px) {
    width: 370px;
    right: -370px;

    .indicators {
      text-align: initial;
      padding-left: 15px !important;
    }

    .col-custom {
      width: 100%;
    }

    .inline-custom {
      display: inline !important;
      &.inline-custom-lm {
        margin-left: 10px;
      }
    }
  }
}