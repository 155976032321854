@import "./src/styles/variables";

.edb-form {
  label {
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
    color: $mainLightGreyText;
    font-weight: 400;
    text-align: left;
  }

  select,
  input[type="text"],
  input[type="email"],
  input[type="tel"]{
    height: 30px;
    font-size: 13px;
    color: #303030;
  }

  textarea {
    font-size: 13px;
    color: $mainLightGreyText;
  }

  .form-footer {
    padding: 20px;
    background: $mainPailBlue;
    &--white {
      padding: 10px 0 0 0;
      background: $mainWhite;
    }
  }

  .form-fields {
    padding: 20px 20px 10px;
    min-height: 100px;
  }

  .form-buttons {
    padding: 20px 20px 0 20px;
    text-align: right;
    .edb-btn {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

.edb-field {
  position: relative;
  input[type="text"] {
    height: 30px;
    outline: none;
  }
  &__clear {
    position: absolute;
    right: 5px;
    top: 3px;
    color: $mainGreyText;
    cursor: pointer;
  }
}
