.select-field-custom {
  &__control {
    min-height: 40px !important;
    border: 1px solid #d2deea !important;
  }
  &__value-container {
    padding: 3px 0 3px 10px !important;
    font-size: 16px !important;
  }
  &__input-container {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  &__menu {

  }
  &__menu-list {
    scrollbar-width: thin !important;
    &::-webkit-scrollbar {
      max-width: 10px !important;
    }
  }
  &__option {
    padding: 5px 10px !important;
    line-height: 16px !important;
    font-size: 16px !important;
    color: #303030 !important;
    cursor: pointer !important;
    &--is-focused {
      background-color: #e9ecef !important;
    }
    &--is-selected {
      background-color: #f5fafd !important;
      color: #495c68 !important;
    }
  }
}
.invalid-field {
  .select-field-custom__control {
    border-color: #e9322d !important;
    color: #b94a48 !important;
  }
}