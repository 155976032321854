@import "./src/styles/variables";

.turnover-details {
  padding: 0 15px;

  .turnover-details-container {
    background: $mainWhite;
    border-radius: 5px;
  }
  .tab-content {
    display: none;
    &.tab-active {
      display: block;
    }
    .empty {
      padding: 20px;
    }
  }
}