@import "src/styles/variables";

.table-filter-wrapper {
  max-height: 300px;
  overflow-y: auto;
  margin: 0 10px;
  padding: 10px 20px;
  background: $mainWhite;
  .caption {
    color: $mainGreyText;
    font-size: 13px;
    font-weight: 700;
    margin-right: 20px;
  }
  .clear-all {
    color: $mainLightText;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
  }
  .attribute-filter-wrapper {
    margin-right: 20px;
    .label {
      margin: 0 2px 0 0;
      font-size: 12px;
      font-weight: 700;
      color: $mainGreyText;
    }
    .values-wrapper {
      margin-right: 5px;
    }
    .value, .value-all {
      display: inline-block;
      margin: 0;
      border: 1px solid rgba(118,136,145,.3);
      border-radius: 12px;
      font-size: 12px;
      color: rgba(118,136,145,.85);
      user-select: none;
      svg {
        position: relative;
        top: 1px;
        cursor: pointer;
      }
    }
    .value {
      padding: 1px 10px 2px;
      margin-right: 5px;
      svg {
        margin-left: 5px;
      }
    }
    .value-all {
      padding: 1px 6px 2px;
    }
  }
}
