@import "./src/styles/variables";

.leavers-chart-doughnut-wrapper {
  position: relative;
  height: 75%;
  width: 75%;
  min-width: 200px;
  max-width: 400px;
  @media (max-width: 1200px) {
    height: 100%;
    width: 100%;
  }
  @media (min-width: 1600px) {
    margin: 0 auto;
  }
}