@import './src/styles/variables';
@import './src/styles/mixins';

#closeWorkRecord {
  @include popupHeader();
  width: 570px;

  .select-field-custom {
    &__control {
      min-height: 32px !important;
      max-height: 32px !important;
    }
    &__value-container {
      padding: 0 12px !important;
      line-height: 16px !important;
      font-size: 13px !important;
    }
    &__single-value {
      margin: 0 !important;
    }
    &__indicator-separator {
      margin-top: 6px !important;
      margin-bottom: 6px !important;
    }
    &__indicator {
      padding: 4px !important;
    }
    &__option {
      padding: 6px 12px !important;
      font-size: 13px !important;
      &--is-focused {
        background-color: #e9ecef !important;
      }
      &--is-selected {
        background-color: #f5fafd !important;
        color: #495c68 !important;
      }
    }
  }

  .info {
    color: $mainLightDarkText;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .084px;
    margin-bottom: 31px;
  }

  .form-close-wr {
    padding: 20px;
  }

  .form-close-wr-header {
    padding: 20px;
  }

  .form-close-wr-body {
    padding: 20px 20px 10px 20px;

    .form-control {
      height: 32px;
      font-size: 12px;
    }
  }

  .form-close-wr-footer {
    padding-top: 40px;
    display: flow-root;
    .form-close-wr-footer-cancel-button {
      margin-right: 20px;
    }
    .edb-pull-right {
      float: right;
    }
  }

  .form-close-wr-header {
    background: $mainPailBlue;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    .main-attribute {
      color: $mainGreyText;
      font-size: 13px;
      font-weight: 600;
    }
    .secondary-attribute {
      color: $mainLightGreyText;
      font-size: 12px;
      font-weight: 400;
      span {
        margin-right: 22px;
      }
    }
  }

  .form-close-wr-body {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid $mainPailBlue;
  }

  .invalid-field {
    border-color: #e9322d !important;
    color: #b94a48;
    > option {
      color: #303030;
    }
  }
  div.text-danger {
    font-size: 12px;
  }

  .react-datepicker-wrapper + svg {
    top: 6px !important;
  }

  .rbt-menu.dropdown-menu {
    max-height: 200px !important;
  }
}
