@import "./src/styles/variables";

$skillLevelWidth: 80px;
$paddingInNameField: 10px;

.work-record-skills {
  .work-record-skill {
    border-radius: 12px;
    margin-bottom: 5px;
    border: 1px solid $mainSmoke;
    padding: 4px 0;
    font-size: 12px;
    color: $mainDarkGreyText;
    &--level {
      &-1 {
        background-color: $mainSmoke;
        color: $mainWhiteText;
      }
      &-2 {
        margin-left: $paddingInNameField;
        background-color: $mainSoftBlue;
      }
      &-3 {
        margin-left: $paddingInNameField + 10px;
        background-color: $mainSkyBlue;
        border: 0;
      }
      &-4 {
        margin-left: $paddingInNameField + 20px;
      }
    }
    &__name {
      float: left;
      white-space: nowrap;
      padding-left: 10px;
    }
    &__level {
      float: right;
      padding-right: 10px;
    }
  }
}

.work-record-skills--collapsable {
  .work-record-skill {
    &__control {
      float: left;
      height: 100%;
      margin-left: 10px;
      cursor: pointer;
    }
    &.hidden {
      display: none;
    }
  }
}

.work-record-skills--change {
  .work-record-skill {
    &--remove {
      color:$mainRedText;
      position: relative;
      &::before {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0;
        right: 0;
        content: '';
        width: 98%;
        margin-left: 1%;
        border-bottom: 1px solid $mainRed;
      }
    }
  }
}

.work-record-skills--preview {
  .work-record-skill {
    &.hidden {
      display: none;
    }
  }
}
