.turnover-value-wrapper {
  font-family: "Source Sans Pro";
  .turnover-value {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 4px;
    color: #768591;
  }
  .turnover-value-label {
    font-size: 18px;
    font-weight: 600;
  }
  .turnover-value-range {
    font-size: 14px;
    color: #768591;
  }
}