@import "./src/styles/variables";
@import "./src/styles/mixins";

.notification {
  position: relative;
  width: inherit;
  height: inherit;
  &.active {
    background-color: #ebf2f5;
    &::before {
      content: "";
      display: inline-block;
      width: 100%;
      height: 4px;
      position: absolute;
      top: 0;
      background: $mainDarkBlue;
    }
  }
  &--title {
    width: auto;
    height: inherit;
    background: url("./../../../assets/images/navbar/new-bell.png") no-repeat center center;
  }
  &--results {
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 1000;
    min-height: 200px;
    max-height: 660px;
    width: $notificationWidth;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    color: #000;
    background: #fff;
    @include popup-box-shadow;
    &_header {
      text-transform: uppercase;
      text-align: center;
      padding: 15px 0;
      font-size: 12px;
      color: $mainGreyText;
      background: $mainDarkBlue;
      font-weight: 600;
      letter-spacing: 0.8px;
    }
    > ul {
      > li {
        width: $notificationWidth/2 !important;
        background-color: $mainDarkBlue;
        text-align: center;
        > a.nav-link {
          &.active {
            background-color: $mainDarkBlue;
            color: #fff !important;
            border: none;
            border-bottom: 4px solid #039be5;
            padding: 14px 0;
            &:after {
              display: none !important;
            }
          }
        }
      }
    }
    .tab-content {
      height: 400px;
    }
  }
}