.work-record-navigation-staff {

  .avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }
  .info {
    padding-left: 20px;
  }
  .full-name {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    word-wrap: break-word;
    overflow: hidden;
  }
  .username {
    color: #768891;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .07px;
    &.username--align {
      margin: 5px 0 0 0;
    }
  }
  .info-trigger {
    font-size: 12px;
    color: #039be5;
    cursor: pointer;
  }
  .contact-info {
    padding: 20px 0 10px 50px;
  }

  .user-card {
    position: relative;
    width: 100%;
    text-align: center;
    &__status {
      position: absolute;
      bottom: 2px;
      width: 100%;
      text-align: center;
      > .status {
        padding: 2px 8px;
        font-size: 10px;
        font-weight: 600;
      }
    }
    &__email {
      font-size: 12px;
      color: #039be5;
    }
  }
}
