//variables
$spinnerSize: 32px;
$color1: #4285f4;
$color2: #de3e35;
$color3: #f7c223;
$color4: #1b9a59;

//mixins
@mixin spinner-half {
  position: absolute;
  top: 0;
  overflow: hidden;
  width: $spinnerSize / 2;
  height: $spinnerSize;
}

@mixin circle {
  width: $spinnerSize;
  height: $spinnerSize;
  border: 4px solid $color1;
  border-radius: 50%;
  border-bottom-color: transparent;
}

@mixin circle-colors($direction) {
  0%, 100% {
    border-color: $color1;
    border-#{$direction}-color: transparent;
    border-bottom-color: transparent;
  }
  25% {
    border-color: $color2;
    border-#{$direction}-color: transparent;
    border-bottom-color: transparent;
  }
  50% {
    border-color: $color3;
    border-#{$direction}-color: transparent;
    border-bottom-color: transparent;
  }
  75% {
    border-color: $color4;
    border-#{$direction}-color: transparent;
    border-bottom-color: transparent;
  }
}

.spinner {
  &__centered-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 2000;
    display: block;
    width: 100%;
    transform: translateY(-50%);
  }

  &__transform-wrapper {
    position: relative;
    margin: 0 auto;
    text-align: center;
    transform: translate3d(0, 0, 0);
  }

  &__outer {
    display: inline-block;
    width: $spinnerSize;
    height: $spinnerSize;
    animation: rotate 2.25s linear infinite;
  }

  &__inner {
    display: inline-block;
    width: $spinnerSize;
    height: $spinnerSize;
    animation: sporadic-rotate 5s ease infinite;
  }

  &__left-half {
    @include spinner-half;
    left: 0;
  }

  &__right-half {
    @include spinner-half;
    left: 50%;
  }

  &__circle--left {
    @include circle;

    border-right-color: transparent;
    animation: animate-left-circle 1.25s ease infinite, left-circle-colors 5s linear infinite;
  }

  &__circle--right {
    @include circle;

    position: relative;
    right: 100%;
    border-left-color: transparent;
    animation: animate-right-circle 1.25s ease infinite, right-circle-colors 5s linear infinite;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sporadic-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

@keyframes animate-left-circle {
  0%, 100% {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
}

@keyframes animate-right-circle {
  0%, 100% {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
}

@keyframes right-circle-colors {
  @include circle-colors('left');
}

@keyframes left-circle-colors {
  @include circle-colors('right');
}


