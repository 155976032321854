@import "./src/styles/variables";

#hardSkills,
#softSkills,
#languageSkills {
  max-width: 900px;
  width: 900px;
  .modal-body {
    padding: 0;
  }
}

.skill-edit-wrapper {
  height: 580px;
  max-height: 580px;
  border-radius: 4px;
  font-family: "Source Sans Pro", sans-serif;
  .skill-sidebar {
    width: 175px;
    height: 580px;
    overflow-y: auto;
    background: $mainSkyDarkBlue;
    scrollbar-width: thin;
    &__title {
      padding-left: 20px;
      height: 105px;
      padding-top: 28px;
      color: $mainDarkText;
      font-size: 18px;
      font-weight: 600;
    }
    &__body {
      .filter {
        padding: 15px 0 15px 20px;
        cursor: pointer;
        color: $mainDarkText;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        &__amount {
          font-size: 10px;
          line-height: 12px;
          color: $mainDarkGreyText;
          padding-left: 5px;
        }
      }
    }
  }
  .skill-header {
    height: 70px;
    background-color: #f5f9fb;
    padding: 20px;
    border-bottom: 1px solid #e9eef4;
    .tabs-control {
      float: left;
      .tabs-control__button {
        display: block;
        width: 131px;
        height: 100%;
        float: left;
        padding: 0;
        margin-right: -1px;
        border: 1px solid $mainLightBlue;
        text-align: center;
        line-height: 30px;
        color: $mainLightText;
        font-weight: 400;
        font-size: 12px;
        text-transform: uppercase;
        &.active {
          background: $mainLightBlue;
          color: $mainWhiteText;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .search {
      float: right;
      input[name="search"] {
        width: 200px;
        border: 1px solid #d2deea;
        padding: 5px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .skill-content {
    height: 430px;
    overflow-y: auto;
    scrollbar-width: thin;
    .tab-pane {
      padding: 20px;
    }
  }
  .skill-footer {
    height: 80px;
    padding: 10px 20px;
    background: $mainPailBlue;
  }
}
