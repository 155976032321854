$sidebarSmallWidth: 50px;
$sidebarAnimationDuration: 0.5s;

//colors
$primaryBtnColor: rgb(80, 209, 11);
$secondaryBtnColor: rgb(4, 186, 255);
$blueLinkColor: rgb(0, 153, 255);
$whiteLinkColor: rgb(102, 120, 129);
$blackLinkColor: rgb(118, 136, 145);
$activeLinkColor: rgb(0, 173, 239);
$darkGreyTextColor: rgb(102, 102, 102);
$greyTextColor: rgb(153, 153, 153);
$disabledFilterColor: rgba(204, 204, 204, .6);

//input sizes
$inputWidthSmallMedium: 257px;

//font sizes
$mediumFontSizeType1: 13px;
$mediumFontSizeType2: 14px;
$largeFontSize: 16px;
$extraLargeFontSize: 22px;
$smallFontSize: 11px;


//heights
$smallButtonHeight: 24px;

/////////////////////////////////////////////////
////////          New Visual              ///////
/////////////////////////////////////////////////

//main layout
$sidebarWidth: 246px;
$navbarHeight: 60px;
$minContentHeight: 768px;
$edbLayoutOverlayIndex: 5;

// Global
//common colors
$mainDarkBlue: #313942;
$mainLightBlue: #039be5;
$mainSkyBlue: #e9f0f3;
$mainSkyDarkBlue: #d7e4e9;
$mainWetAsphalt: #1d2329;
$mainDryAsphalt: #323b44;
$mainWhite: #fff;
$mainSmokeBlue: #d2deea;
$mainSoftBlue: #ebf2f5;
$mainGreen: #03b200;
$mainLightGreen: #66bb6a;
$mainYellow: #f8ff46;
$mainLightYellow: #fffe7b;
$mainRed: #ff3538;
$mainLightRed: #ff5548;
$mainGrey: #727b85;
$mainLightGrey: #38434a;
$mainSmokeGrey: #b8c7d5;
$mainWhiteGrey: #647483;
$mainSmoke: #adc4da;
$mainPailBlue: #d9e6eb;
$mainPurple: #ba68c8;
$mainSand: #ffefc4;
$tooltipBackgroundGrey: #637484;
$mainGrey2020: #768691;
$mainTextInputBackgroundDisabled: #f8fdff;
$mainTextInputBorderDisabled: #e4ecf0;

//colors for text
$mainGreyText : #768891;
$secondaryGreyText : #667881;
$mainLightText : #039be5;
$mainDarkGreyText: #647483;
$mainWhiteText: #fff;
$mainLightGreyText: #829199;
$mainDarkText: #2b3337;
$mainLightDarkText: #1d2b32;
$mainRedText: #ff5252;
$mainLightRedText: #f0563d;
$mainTextInputDisabled: #303030;

//common input sizes
$smallInputWidth: 160px;
$bigInputHeight: 35px;
$extraSmallInputWidth: 145px;
$preMediumInputWidth: 230px;
$mediumInputWidth: 315px;
$largeInputWidth: 458px;
$standartInputWidth: 310px;
$inputHeight: 30px;
$edbInputCrossSize: 10px;

//common font parameters
$smallFontSize: 12px;
$mediumFontSize: 14px;
$largeFontSize: 18px;
$hugeFontSize: 24px;
$mediumFontWeight: 400;
$largeFontWeight: 600;

// notification_popup
$notificationWidth: 390px;

//borders radiuses
$popupRadius: 4px;

//datepicker icon block width
$datepickerIconBlockWidth: 36px;

//it's used in sidebar and preview wr header
$staffPhotoSize: 70px;
$marginRightFromPhoto: 15px;

// cv form variables
$blockBackgroundColor: #f9fbfc;
$blockBorderColor: #e7eff2;
$deleteBtnGreyColor: #adb8bd;
$deleteBtnBorderColor: #dfe3e6;
$deleteBtnLightBlueColor: #cdebfa;

$color-main-theme--purple-dark: #1e0640;
$color-main-theme--purple-medium: #7819ff;
$color-main-theme--purple-light: #ddc6ff;
$color-main-theme--black: #000000;
$color-main-theme--white: #ffffff;
$color-main-theme--green: #4cd964;

$bigButtonHeight: 36px;
$bigButtonLightBlueHeight: 30px;
$bigButtonBorderWidth: 1px;

$searchFieldWidth: 86%;
$checkboxLeftPadding: 25px;

@font-face {
  font-family: 'Proxima Nova Bold';
  src: url('./../assets/font/ProximaNova-Bold.eot');
  src: url('./../assets/font/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
  url('./../assets/font/ProximaNova-Bold.woff') format('woff'),
  url('./../assets/font/ProximaNova-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('./../assets/font/ProximaNova-Regular.eot');
  src: url('./../assets/font/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('./../assets/font/ProximaNova-Regular.woff') format('woff'),
  url('./../assets/font/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Light';
  src: url('./../assets/font/ProximaNova-Light.eot');
  src: url('./../assets/font/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
  url('./../assets/font/ProximaNova-Light.woff') format('woff'),
  url('./../assets/font/ProximaNova-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.font--proxima-nova-bold {
  font-family: 'Proxima Nova Bold';
}

.font--proxima-nova-regular {
  font-family: 'Proxima Nova Regular';
}

.font--proxima-nova-light {
  font-family: 'Proxima Nova Light';
}
