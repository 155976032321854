@import "src/styles/variables";

.employee-employment-type {
  width: auto;
  display: flex;
  .type {
    width: 120px;
    height: 32px;
    margin-left: -1px;
    border: 1px solid $mainLightBlue;
    font-size: 12px;
    color: $mainLightBlue;
    text-align: center;
    line-height: 30px;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    &.active {
      color: $mainWhiteText;
      background: $mainLightBlue;
    }
    &:nth-of-type(1) {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    &:nth-of-type(3) {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
}
