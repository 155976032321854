.unit-tree-chart {

  svg {
    width: 100%;
    background: #f2f2f2;
    touch-action: none;
  }

  text-align: center;

  .link {
    fill: none;
    stroke: #768891;
    stroke-width: 1px;
    &--active {
      stroke: #039be5;
      stroke-width: 1.5px;
    }
  }

  .bordered {
    stroke: #039be5;
    stroke-width: 2px;
  }

  .node {
    cursor: pointer;

    foreignObject {

      body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: transparent;
        height: 100%;
        padding: 3px;
        text-align: center;

        .block-people,
        .block-tree {
          display: flex;
          align-items: center;
        }
      }
      img {
        margin-right: 5px;
      }
      span {
        margin: 0 !important;
        min-height: 18px;
        &.name {
          margin-bottom: 8px !important;
          margin-top: 8px !important;
          font-weight: 600;
        }
      }
    }
  }
}