@import "./src/styles/variables";

.work-record-feedback {
  .work-record-main {
    &__header {
      height: 97px;
    }
    &__body {
      .education-list {
        padding-top: 24px;
        &__empty {
          font-size: 14px;
        }
      }
    }
  }
  .staff-wr-code {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 20px 25px 10px;
    background: url('./../../../assets/images/work-record/bookmark.svg') no-repeat center center;
    color: #fff;
    font-size: $mediumFontSizeType1;
    text-align: center;
  }
}
