@import './src/styles/variables';
.skill-field {
  .skill {
    margin-bottom: 20px;
    div {
      float: left;
      width: 60%;
      height: 30px;
      padding-left: 5px;
      background: $mainWhite;
      border: 1px solid $mainSmokeBlue;
      border-right: none;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      font-size: 13px;
      line-height: 30px;
      font-weight: 400;
      color: $mainDarkGreyText;
    }
    select {
      float: left;
      width: 30%;
      height: 30px;
      background: $mainWhite;
      border: 1px solid $mainSmokeBlue;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      font-size: 13px;
      line-height: 30px;
      font-weight: 400;
      color: $mainDarkGreyText;
      outline: none;
    }
    .remove-skill {
      float: left;
      width: 20px;
      height: 30px;
      padding: 7px 0 7px 5px;
      color: $mainDarkGreyText;
      cursor: pointer;
    }
  }
  .select-skill {
    .rbt {
      width: 90%;
      height: 30px;
      input {
        height: 30px;
        outline: none;
        &:focus {
          outline: none;
        }
      }
      .rbt-menu {
        max-height: 200px !important;
      }
    }
  }
  label {
    display: block;
    font-size: 12px;
    color: $mainLightGreyText;
    font-weight: 400;
  }
}
