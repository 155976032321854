@import './src/styles/variables';
@import './src/styles/mixins';

#addFeedback, #editFeedback {
  @include popupHeader();
  font-family: "Source Sans Pro", sans-serif;
  .modal-header {
    padding: 30px 20px;
    background-color: #fff;
    > .modal-title {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.9px;
      color: $mainLightBlue;
    }
  }
  .modal-body {
    padding: 0;
    background-color: #F5F9FC;
    color: #303030;
    .form-group {
      .text-danger {
        color: #b94a48 !important;
        font-size: 12px;
        line-height: 16px;
      }
    }
    input {
      height: 32px;
      width: 100% !important;
      font-size: 13px;
    }
    textarea {
      min-height: 200px;
    }
    .select-field-custom {
      &__control {
        min-height: 30px !important;
      }
      &__indicator {
        padding: 4px !important;
      }
      &__indicator-separator {
        margin-top: 4px !important;
        margin-bottom: 4px !important;
      }
      &__value-container {
        padding: 0 6px 0 10px !important;
        font-size: 13px !important;
      }
    }
    .react-datepicker-wrapper + svg {
      top: 6px !important;
    }
    .form-footer {
      background-color: #f5f9fc;
    }
    .invalid-field {
      border-color: #e9322d !important;
      color: #b94a48;
    }
  }
}

@media (min-width: 992px) {
  #addFeedback,
  #editFeedback {
    max-width: 700px !important;
  }
}
