@import "src/styles/variables";
@import "src/styles/mixins";

$edbWrPreviewPadding: 20px;
$edbWrPreviewHeader: 110px;
.wr-preview {
  background-color:  $mainWhite;
  position: absolute;
  top: 32px;
  right: 0;
  bottom: 0;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
  z-index: $edbLayoutOverlayIndex - 1;
  width: 75%;

  &__header {
    height: $edbWrPreviewHeader;
    background-color: $mainSoftBlue;
    padding: $edbWrPreviewPadding;
    &--avatar {
      width: 246px;
    }
    &--action {
      width: calc(100% - 246px);
    }
    .work-record-navigation-staff {
      padding: 0;
      .full-name {
        color: #2b3337;
      }
    }
  }

  &__header-close-icon {
    color: $mainWhiteGrey;
    position: absolute;
    top: $edbWrPreviewPadding;
    right: $edbWrPreviewPadding;
    &:hover {
      cursor: pointer;
    }
  }

  &__header-view-profile {
    @include vertical-align;
  }

  &__content {
    position: absolute;
    top: $edbWrPreviewHeader;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    padding: $edbWrPreviewPadding;
    &--position {
      margin-bottom: 10px;
    }
  }
  &__content-soft-skills {
    margin-right: 10px;
  }
  .edb-wr__title {
    color: #768891;
    font-size: 12px;
    font-weight: 14px;
    letter-spacing: 0.9px;
    margin-bottom: 20px;
  }
}