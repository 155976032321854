@import './src/styles/variables';
@import './src/styles/mixins';

#popupDescription, #addNewWorkRecord {

  .modal-header {
    height: 72px;
    padding: 22px 40px;
    background: #F5F7F9;
    .modal-title {
      margin: 0;
      line-height: 28px;
      font-size: 22px;
      font-weight: 600;
      color: #1D2B32;
    }
  }

  .modal-body {
    padding: 0;

    .form-footer {
      padding: 20px 40px;
      background: #F5F7F9;
    }
    .form-fields {
      height: calc(100vh - 60px - 100px - 40px);
      max-height: 520px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 24px 40px;
      scrollbar-width: thin;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar {
        width: 4px;
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #727b85;
      }
    }

    .popover-base {
      > svg {
        > path {
          fill: #ffae00;
        }
      }
    }

    .invalid-field {
      border-color: #e9322d !important;
      color: #b94a48;
    }

    .edb-btn--primary:hover {
      background-color: #05aae9;
    }

    .form-group {
      .text-danger {
        color: #b94a48 !important;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .react-datepicker-wrapper {
      width: 100%;
    }

    .wr-form--employment,
    .wr-form--location,
    .wr-form--subordination {
      padding-top: 16px;
    }

    .wr-form--position,
    .wr-form--employment,
    .wr-form--location {
      padding-bottom: 0;
      border-bottom: 1px solid #EBEEF2;
    }

    .wr-form--title {
      margin-bottom: 12px !important;
      line-height: 24px;
      font-size: 18px;
      font-weight: 600;
      color: #1D2B32 !important;
    }

    .wr-form--label {
      display: inline-block;
      margin: 6px 0;
      border: 0 !important;
      line-height: 18px;
      font-size: 14px;
      font-weight: 400;
      color: #768891;
    }

    .form-control {
      width: 100% !important;
      height: 40px !important;
      border: 1px solid #d2deea !important;
      border-radius: 4px;
      padding: 6px 12px;
      font-size: 16px;
      font-weight: 400;
      color: #1D2B32;
    }

    select.form-control {
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.1251 1.00002L6.0051 4.88002L9.8851 1.00002C10.2751 0.61002 10.9051 0.61002 11.2951 1.00002C11.6851 1.39002 11.6851 2.02002 11.2951 2.41002L6.7051 7.00002C6.3151 7.39002 5.6851 7.39002 5.2951 7.00002L0.705098 2.41002C0.315098 2.02002 0.315098 1.39002 0.705098 1.00002C1.0951 0.62002 1.7351 0.61002 2.1251 1.00002Z' fill='%23A6B7C2'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center right 12px;
    }

    .rbt-aux {
      bottom: 3px;
    }

    .form-check {
      margin-bottom: 15px;
      padding-left: 2px;
      &-input {
        width: 21px;
        height: 18px;
        margin-top: 2px;
        margin-bottom: 0;
        margin-right: 11px;
      }
      &-label {
        margin: 0 !important;
        font-size: 14px;
        font-weight: 400;
        color: #1D2B32;
      }
    }

    .invalid-field {
      border-color: #e9322d !important;
      color: #b94a48;
      > option {
        color: #303030;
      }
    }
  }
}

.add-employee-form {
  .form-group {
    .text-danger {
      color: #b94a48 !important;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }

  .wr-form--employment,
  .wr-form--location,
  .wr-form--subordination {
    padding-top: 40px;
  }

  .wr-form--position,
  .wr-form--employment,
  .wr-form--location {
    padding-bottom: 25px;
    border-bottom: 1px solid #EBEEF2;
  }

  .wr-form--title {
    margin-bottom: 12px !important;
    line-height: 24px;
    font-size: 18px;
    font-weight: 600;
    color: #1D2B32 !important;
  }

  .wr-form--label {
    display: inline-block;
    margin: 6px 0;
    border: 0 !important;
    line-height: 18px;
    font-size: 14px;
    font-weight: 400;
    color: #768891;
  }

  .form-control {
    width: 100% !important;
    height: 40px !important;
    border: 1px solid #d2deea !important;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 400;
    color: #1D2B32;
  }

  select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.1251 1.00002L6.0051 4.88002L9.8851 1.00002C10.2751 0.61002 10.9051 0.61002 11.2951 1.00002C11.6851 1.39002 11.6851 2.02002 11.2951 2.41002L6.7051 7.00002C6.3151 7.39002 5.6851 7.39002 5.2951 7.00002L0.705098 2.41002C0.315098 2.02002 0.315098 1.39002 0.705098 1.00002C1.0951 0.62002 1.7351 0.61002 2.1251 1.00002Z' fill='%23A6B7C2'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center right 12px;
  }

  .form-check {
    margin-bottom: 15px;
    padding-left: 2px;
    &-input {
      width: 21px;
      height: 18px;
      margin-top: 2px;
      margin-bottom: 0;
      margin-right: 11px;
    }
    &-label {
      margin: 0 !important;
      font-size: 14px;
      font-weight: 400;
      color: #1D2B32;
    }
  }
  .rbt-menu.dropdown-menu {
    max-height: 200px !important;
  }
}

.form-position-description,
.form-add-work-record {
  .rbt-menu.dropdown-menu {
    max-height: 200px !important;
  }
}
