@import "./src/styles/variables";

.image-field {
  width: 100%;
  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .preview {
    position: relative;
    width: 70px;
    height: 70px;
    float: left;
    .avatar {
      position: absolute;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
  }
  .fileUploader {
    width: auto;
    float: left;
    margin-top: 17px;
    .fileContainer {
      padding: 0;
      margin: 0;
      box-shadow: none;
      display: block;
      p {
        margin: 0;
      }
      .chooseFileButton {
        white-space: nowrap;
        width: 110px;
        height: 36px;
        margin: 0;
        padding: 2px 10px;
        border-radius: 25px;
        border: 1px solid $mainLightBlue;
        background-color: #fff;
        font-size: 14px;
        font-weight: 400;
        color: $mainLightBlue;
        text-align: center;
        line-height: 31px;
      }
      .uploadPicturesWrapper {
        display: none;
      }
    }
  }
}
