@import "./src/styles/variables";

.turnover-detail-item {
  padding: 20px;
  .turnover-detail-item-container {
    background: $mainSoftBlue;
    border-radius: 5px;
    padding: 25px 32px 15px 32px;
    margin-bottom: 20px;
    .multiple-chart-wrapper {
      width: 100%;
      height: 180px;
      padding-top: 20px;
      &.long {
        overflow-x: auto;
      }
      .chart-bar-wrapper {
        width: 90%;
        height: 140px;
      }
    }
    .single-chart-wrapper {
      width: 80%;
      padding-top: 40px;
      margin: 0 auto;
      .chart-bar-horizontal-wrapper {
        width: 100%;
        height: 60px;
        vertical-align: middle;
      }
    }
    .no-data-wrapper {
      padding: 20px;
    }
  }
  .customClearfix {
    overflow-x: auto;
    &:after{
      content: "";
      display: table;
      clear: both;
    }
  }

  .commonStat {
    position: relative;
    z-index: 1;
    float: left;
    width: 200px;
    padding-left: 15px;
    border-right: 1px solid #ccd3df;
  }
  .chartWrapper {
    float: left;
    width: 100%;
    padding-left: 210px;
    margin-left: -205px;
    box-sizing: border-box;
  }
}