@import "./src/styles/variables";
@import "./src/styles/mixins";

.content {
  position: relative;
}

.work-record-navigation-toggle {
  display: none;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 15px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 4px;
  color: $mainLightText;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  &:hover {
    background: $mainWhite;
  }
}

.work-record-navigation-wrapper {
  width: 246px;
  height: 100%;
  transition: 0.5s ease all;
}

.work-record {

  .work-record-partial {
    position: relative;

    &__body, &__footer {
      width: 100%;
    }

    &__body {
      position: absolute;
      top: 0;
      bottom: 80px;
      left: 0;
      right: 0;
      border-radius: 15px;
      overflow-y: auto;
      @include custom-scroll;
    }

    &__footer {
      height: 60px;
      position: absolute;
      bottom: 10px;
      background-color: $mainPailBlue;

      .edb-btn {
        position: relative;
        top: 17px;
      }
    }

    .panel-block {
      padding: 40px;
      background: white;
      border-radius: 4px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .2);
      margin-bottom: 10px;

      &--left {
        margin: 0 10px 10px 0;
      }

      &--right {
        margin: 0 0 10px 0;
      }

      &__title {
        color: $mainGreyText;
        font-size: 12px;
        letter-spacing: .9px;
        padding: 20px 0;
        text-transform: uppercase;

        &--small {
          padding: 0 0 15px 0;
        }
      }
    }
  }
}

//@media (max-width: 989px) {
//  .work-record-navigation-toggle {
//    display: block;
//  }
//  .work-record-navigation-wrapper {
//    position: absolute;
//    z-index: 5;
//    left: 15px;
//  }
//  .work-record-navigation-wrapper--hidden {
//    left: -246px;
//    padding: 0;
//  }
//}
