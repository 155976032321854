@import "./src/styles/variables";

$tooltipWidth: 217px;
$tooltipHeight: 255px;
$mainOvalBackgroundColor: #f1f5f8;
$tooltipLmSecondLevel: #d6e1ec;
$unknownStaffNodeColor: #dbdbdb;

.fa-sitemap {
  font-size: 10px;
  color: $mainLightBlue;
  cursor: pointer;
}

#popover-lineManager {
  .popover-body {
    max-width: $tooltipWidth;

    ul {
      list-style: none;
      padding: 21px 0 0 47px;
      margin-bottom: 9px;
      > li {
        position: relative;
        padding: 0 0 12px 0;

        &:before {
          position: absolute;
          left: -20px;
          top: 21%;
          z-index: 1;
          content: '';
          width: 9px;
          height: 9px;
          border-radius: 50%;
          transform: translateY(-40%);
          background: $mainOvalBackgroundColor;
        }

        &:nth-child(1):before {
          background: $mainSmoke;
        }

        &:nth-child(2):before {
          background: $tooltipLmSecondLevel;
        }

        &:nth-child(3):before {
          background: $mainSoftBlue;
        }

        &:not(:last-child):after {
          content: '';
          width: 1px;
          height: 100%;
          background: $mainSkyBlue;
          position: absolute;
          left: -16px;
          top: 21%;
        }

        &.unknown-staff-node {
          color: $unknownStaffNodeColor;
          &:before {
            border: solid 1px $mainSkyBlue;
            background-color: $color-main-theme--white !important;
          }
        }
      }
    }

    .view-org-chart {
      position: relative;
      text-align: center;
      margin-bottom: 16px;

      a {
        position: relative;
        color: #337ab7;
        text-decoration: none;
        &:before {
          z-index: 1;
          content: '';
          width: 14px;
          height: 14px;
          background: url('./../../../assets/images/lm-tree/view-org-chart.svg');
          position: absolute;
          transform: translateY(-45%);
          top: 50%;
          left: -18px;
        }
      }
    }
  }
}