.skills-form {
  &--soft-skills,
  &--languages {
    padding-top: 40px;
  }

  &--hard-skills,
  &--soft-skills,
  &--languages {
    padding-bottom: 25px;
  }

  &--hard-skills,
  &--soft-skills {
    border-bottom: 1px solid #EBEEF2;
  }

  &--title {
    margin-bottom: 16px !important;
    line-height: 24px;
    font-size: 18px;
    font-weight: 600;
    color: #1D2B32 !important;
  }

  &--label {
    margin: 6px 0px !important;
    border: 0 !important;
    line-height: 18px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #768891 !important;
  }

  &--remove {
    position: absolute;
    bottom: 1px;
    left: 20px;
    line-height: 40px;
    font-size: 40px;
    font-weight: 100;
    color: #A6B7C2 !important;
  }
}