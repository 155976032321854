@import "./src/styles/variables";

.leavers-details {
  padding: 0 !important;
  overflow-x: hidden;
  overflow-y: auto;
  .leavers-details-container {
    background: $mainWhite;
    border-radius: 5px;
  }
  .details-control {
    height: 55px;
    border-bottom: 1px solid $mainSkyBlue;
    .tabs {
      list-style: none;
      padding-left: 20px;
      .tab {
        display: inline-block;
        margin-right: 32px;
        height: 54px;
        font-family: 'Source Sans Pro';
        font-size: 12px;
        line-height: 50px;
        color: $mainWhiteGrey;
        text-transform: uppercase;
        border-bottom-width: 4px;
        border-bottom-style: solid;
        border-bottom-color: $mainWhite;
        &.active {
          color: $mainDarkBlue;
          border-bottom-color: $mainLightBlue;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .filter-field {
      margin-right: 20px;
      padding-top: 3px;
    }
  }
  .tab-content {
    display: none;
    &.tab-active {
      display: block;
    }
    .empty {
      padding: 20px;
    }
  }
  .p-20 {
    padding: 20px;
  }
}