@import './src/styles/variables';
@import './src/styles/mixins';

#trialStatus {
  @include popupHeader();
  .modal-body {
    padding: 0;
  }
  .form-trialStatus {
    margin: 20px;
    select,
    input {
      height: 32px;
      font-size: 13px;
    }
    .form-buttons {
      button {
        line-height: 18px;
      }
    }
    svg {
      top: 6px !important;
      right: unset !important;
      left: 134px;
    }
    .react-datepicker-wrapper {
      input {
        width: 159px !important;
      }
    }
    .select-field-custom {
      &__control {
        min-height: 32px !important;
        max-height: 32px !important;
      }
      &__value-container {
        padding: 0 12px !important;
        line-height: 16px !important;
        font-size: 13px !important;
      }
      &__single-value {
        margin: 0 !important;
      }
      &__indicator-separator {
        margin-top: 6px !important;
        margin-bottom: 6px !important;
      }
      &__indicator {
        padding: 4px !important;
      }
      &__option {
        padding: 6px 12px !important;
        font-size: 13px !important;
        &--is-focused {
          background-color: #e9ecef !important;
        }
        &--is-selected {
          background-color: #f5fafd !important;
          color: #495c68 !important;
        }
      }
    }
  }
}
