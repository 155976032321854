@import './src/styles/variables';
@import './src/styles/mixins';

#nextEmployer {
  @include popupHeader();
  .rbt .dropdown-menu .dropdown-item {
    line-break: anywhere;
  }
  input {
    height: 32px;
  }
}
