.modal-dialog {
  margin-top: 60px;
}

a:focus, a:hover {
  text-decoration: none!important;
}

.extend-modal-backdrop {
  height: 100% !important;
  width: 100% !important;
}
