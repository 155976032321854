@import "./src/styles/variables";

.work-record-attribute {
  width: 100%;
  &__name {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
    color: #768891;
  }

  &__value {
    width: 100%;
    color: #1d2b32;
    font-size: 14px;
    font-weight: 400;
    line-break: anywhere;
    a {
      text-decoration: none;
      color: $mainLightBlue;
    }
  }

  &--percent {
    .color0 {
      fill: rgb(3, 155, 229);
    }

    .color1 {
      fill: rgb(118, 136, 145);
    }

    .chart-legend {
      fill: rgb(3, 155, 229);
      font-size: 10.5px;
    }
  }

  .icon-wrapper {
    margin-right: 5px;
  }
}
