@import "./src/styles/variables";

.work-record-navigation-list {
  &__title {
    padding: 15px 25px 5px;
    font-size: 12px;
    background-color: $mainWetAsphalt;
    color: $mainGreyText;
    text-transform: uppercase;
  }
  .work-record-navigation-item {
    background-color: $mainWetAsphalt;
    border-bottom: 1px solid $mainLightGrey;
    &--greyout {
      color: rgba(118, 136, 145, 0.5) !important;
      fill: rgba(118, 136, 145, 0.5) !important;
      pointer-events: none;
      background-color: inherit !important;
      &:before {
        content: none !important;
      }
    }
    .work-record-navigation-item-button {
      width: 100%;
      padding: 10px 25px !important;
      text-align: left;
      color: $mainGreyText;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
      &__position {
        font-size: 12px;
        font-weight: bold;
      }
      &__dates {
        font-size: 12px;
        font-weight: 400;
      }
      &__employment {
        font-size: 12px;
        font-weight: 400;
        margin-top: 5px;
      }
    }
    &.active {
      .work-record-navigation-item-button {
        color: $mainWhite;
      }
    }
  }
  .work-record-navigation-item-body {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
    li {
      width: 100%;
    }
    button {
      background: transparent;
      text-align: left;
      border: 0;
      width: 100%;
      &:focus {
        border: 0;
        outline: 0;
      }
    }
    a, button {
      display: block;
      padding-left: 45px;
      line-height: 39px;
      font-size: 12px;
      font-weight: 400;
      color: $mainGreyText;
      text-transform: uppercase;
      .label {
        margin-left: 3px;
      }
      svg {
        fill: $mainGreyText;
      }
      &.active, &:hover {
        background-color: $mainLightBlue;
        color: white;
        text-decoration: none;
        svg {
          fill: white;
        }
      }
      &.active {
        box-shadow: inset -3px 0px 0 0px $mainLightBlue;
      }
    }
  }
  .work-record-navigation-bottom {
    padding: 11px;
    border-top: 1px solid $mainLightGrey;
    color: $mainLightBlue;
  }
}

