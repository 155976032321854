@import "./src/styles/variables";

.dashboard-leavers {
  height: 100%;
  background-color: #e9f0f3;
  &__wrapper {
    padding: 20px 15px;
    background: #e9f0f3;
    border-radius: 5px;
    .details-control {
      position: relative;
      height: 55px;
      background: #fff;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      .tabs {
        list-style: none;
        padding-left: 20px;
        .tab {
          display: inline-block;
          margin-right: 32px;
          height: 54px;
          border-bottom-width: 4px;
          border-bottom-style: solid;
          border-bottom-color: $mainWhite;
          line-height: 50px;
          font-size: 12px;
          font-family: "Source Sans Pro", sans-serif;
          color: $mainWhiteGrey;
          text-transform: uppercase;
          &.active {
            color: $mainDarkBlue;
            border-bottom-color: $mainLightBlue;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
      .filter-field {
        margin-right: 20px;
        padding-top: 3px;
      }
    }
  }
}

.leavers-error {
  background: $mainWhite;
  border-radius: 5px;
  padding: 20px 20px 20px 20px;
  margin-left: 20px;
  .header {
    color: $mainGreyText;
    font-size: 12px;
    font-weight: 100;
    letter-spacing: .9px;
    margin-bottom: 45px;
  }
}
.p15 {
  padding: 0 15px;
}
