@import "./src/styles/variables";

.phone-field {
  &.react-tel-input {
    .form-control {
      width: 100%;
      &[disabled] {
        background: #e9ecef;
      }
    }
  }
}
