@import "./src/styles/variables";

$footerHeight: 60px;

.work-record-navigation {
  width: auto;
  background: $mainWetAsphalt;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $mainGrey;
  }

  .add-wr-button {
    background: $mainWetAsphalt;

    .popup-button {
      font-size: 12px;
      color: $mainLightBlue;
      cursor: pointer;
    }
  }

  .add-wr-button {
    padding: 20px;
    line-height: 18px;
  }

  .edit-profile-button {
    display: inline-block;
    position: absolute;
    top: 25px;
    right: 25px;
    .popup-button {
      cursor: pointer;
      color: #FFF;
    }
  }

  &__header, &__body, &__footer {
    width: 100%;
  }

  &__header {
    position: relative;
    height: auto;
    padding: 25px;
    background-color: $mainDarkBlue;
    .info-trigger {
      font-size: 12px;
      color: #039be5;
      cursor: pointer;
    }
    > .edb-btn {
      margin-top: 12px;
    }
  }

  &__body {
    height: auto;
  }

  &__footer {}
}