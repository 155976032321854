@import './src/styles/variables';

.edb-overlay {
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0;
  background: #000;
  opacity: 0;
  filter: alpha(opacity=60);
  z-index: $edbLayoutOverlayIndex;
  &--extra {
    z-index: $edbLayoutOverlayIndex + 1;
  }
  &.active {
    opacity: 0.6;
  }
}