.slider-value {
  position: absolute;
  top: -7px;
  padding-left: 20px;
  color: #039be5;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: .12px;
}

.rc-slider {
  margin-top: 18px;
  width: 95%;
  .rc-slider-rail {
    background: #d8e0f3;
  }

  .rc-slider-track {
    background: #039be5;
  }

  .rc-slider-handle {
    width: 22px;
    height: 22px;
    top: 1px;
    border-color: #d8e0f3;

    &:active {
      outline: none;
      box-shadow: none;
    }
  }
}
