@import "../../../styles/variables";

.lm-tree-chart {

  &--image-clip {
    position: fixed;
    top: 50px;
  }

  &--image-clip-big {
    position: fixed;
    top: 60px;
  }

  svg {
    width: 100%;
    background: #f2f2f2;
    border-radius: 3px;

    .link {
      fill: none;
      stroke: #d3d3d3;
      stroke-width: 1px;

      &--active {
        stroke: $mainLightBlue;
        stroke-width: 1.5px;
      }
    }

    .node {
      cursor: pointer;

      text {
        font: 12px sans-serif;
        font-weight: 600;
        &.hyper {
          &:hover {
            text-decoration: underline;
            fill: $blueLinkColor;
          }
        }
        &.position {
          font-weight: 400;
          fill: #777777;
        }
      }
      circle {
        fill: #fff;
        stroke: #797979;;
        stroke-width: 1px;
        pointer-events: none;
      }
      .extend-button {
        width: 10px;
        height: 10px;
      }
      .control {
        opacity: 0;
      }
    }
  }

  &--tooltip {
    position: absolute;
    text-align: left;
    width: 200px;
    height: auto;
    padding: 10px;
    font-size: 12px;
    background: #fff;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.15);
    border: none;
    border-radius: 3px;

    &__caption {
      font-size: 12px;
    }

    &__value {
      font-weight: bold;
      color: $mainLightBlue;
      text-align: right;
    }
  }
}

.edb-graph {

  .selected {
    font-weight: bold;
  }

  .not-selected {
    font-weight: normal;
  }
}
