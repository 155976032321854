@import "./src/styles/variables";

.headcount-dynamic-detail-item {
  padding: 20px;
  .headcount-dynamic-detail-item-container {
    background: $mainSoftBlue;
    border-radius: 5px;
    padding: 20px 32px 20px 32px;
    margin-bottom: 20px;
    .multiple-chart-wrapper {
      width: 100%;
      height: 500px;
      padding-top: 30px;
      &.long {
        overflow-x: auto;
      }
      .chart-bar-wrapper {
        height: 180px;
        &.mb {
          margin-bottom: 70px;
        }
      }
    }
    .no-data-wrapper {
      padding: 20px;
    }
    .customClearfix:after {
      content: "";
      display: table;
      clear: both;
    }
    .commonStat {
      position: relative;
      z-index: 1;
      float: left;
      width: 200px;
      padding-left: 15px;
      border-right: 1px solid #ccd3df;
    }
    .chartWrapper {
      float: left;
      width: 100%;
      padding-left: 210px;
      margin-left: -205px;
      box-sizing: border-box;
    }
  }

  .hovered {
    cursor: pointer !important;
  }
}



