@import "src/styles/variables";

.employee-date-range {
  width: 180px;
  height: 30px;
  position: relative;

  > svg {
    position: absolute;
    top: 7px;
    right: 5px;
    color: $mainGreyText;
  }

  .trigger {
    width: 180px;
    height: 30px;
    padding: 0 4px;
    border-radius: 4px;
    border: 1px solid $mainSmokeGrey;
    cursor: pointer;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    color: $mainDarkGreyText;
  }

  .options {
    width: 100%;
    position: absolute;
    z-index: 10;
    top: 30px;
    padding: 8px;
    background: $mainWhite;
    border: 1px solid $mainLightBlue;

    &-hidden {
      display: none;
    }

    .option {
      margin-bottom: 5px;

      .item {
        cursor: pointer;
        color: $mainWhiteGrey;
        font-size: 14px;
        font-weight: 700;
      }

      label {
        max-width: 100%;

        input {
          margin-right: 5px;
        }
      }
    }
  }
}
