@import "./src/styles/variables";

.wr-list-wrapper {
  width: calc(100% - 20px);
  margin: 10px;
  overflow: auto;

  table {
    border: none;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    thead {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: $mainSkyDarkBlue;
      color: $mainGreyText;
      letter-spacing: .483px;

      th {
        position:sticky;
        top:0;
      }

      .cell {
        height: 64px;
        padding: 0 5px 0 10px;
        background: $mainSkyDarkBlue;
        font-size: 11px;
        font-weight: normal;
        color: $mainGreyText;
        text-transform: uppercase;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        white-space: nowrap;
        text-align: left;

        & > span > span {
          padding-left: 5px;
        }
      }
    }

    tbody {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      tr {
        &:nth-child(even) {
          background: $mainSoftBlue;
        }

        &:nth-child(odd) {
          background: $mainWhite;
        }

        .cell {
          height: 50px;
          padding: 5px 5px 5px 10px;
          text-align: left;
          font-size: 12px;
          color: $mainGreyText;

          a {
            cursor: pointer;
            color: $mainLightBlue;
            word-break: break-all;
          }
        }
      }
    }
  }
}
