@import "./src/styles/variables";

.work-record-cv {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  font-size: $mediumFontSize;
  background-color: $mainSoftBlue;
  color: $mainDarkBlue;

  section {
    .col {
      padding-left: 0;
      padding-right: 0;
    }
    &.border-bottom {
      padding-top: 20px;
      padding-bottom: 20px;

      p {
        line-height: 20px;
      }
    }
    pre {
      margin-bottom: 10px;
      padding: 10px;
      font-size: $mediumFontSize;
      white-space: break-spaces;
      word-break: break-word;
      font-family: "Source Sans Pro", sans-serif;
    }
  }

  &__body {
    margin-bottom: 90px;
    padding: 32px 24px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    p {
      font-size: 12px;
      word-break: break-word;
    }
    ul {
      margin-bottom: 10px;
      li {
        margin-bottom: 5px;
        word-break: break-word;
      }
    }
    .box {
      p {
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
    .bg-white {
      padding: 40px;
      border-radius: 4px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    }
    .cv-terms {
      padding-left: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 40px;
      background-color: #fef3e6;
      border-radius: 2px;
      position: absolute;
      top: 0;
      left: 0;

      span {
        font-size: 14px;
        color: #313942;
        padding-left: 10px;
        a {
          color: inherit;
          text-decoration: underline;
          &:hover {
            text-decoration: underline !important;
          }
        }
      }
    }
    .cv-section {
      border-bottom: 1px solid #dee2e6 !important;
      padding-top: 40px;
      padding-bottom: 40px;
      &__title {
        margin-bottom: 10px !important;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #6c757d !important;
      }
    }
  }

  &__status {
    position: absolute;
    top: 0;
    right: 20px;
    width: 100px;
    height: 54px;
    padding: 7px 17px 10px 17px;
    background: url('./../../../assets/images/work-record/bookmark.svg') repeat center center;
    color: #fff;
    font-size: $mediumFontSizeType1 !important;
    text-align: center;
    text-transform: capitalize;
    &-approved {
      background: url('./../../../assets/images/work-record/bookmark-green.svg') repeat center center;
    }
    &-unpublished {
      width: 120px;
      background: url('./../../../assets/images/work-record/bookmark-red.svg') repeat center center;
    }
  }

  &__no-data {
    padding-top: 20px;
    padding-bottom: 20px;
    &-title {
      font-size: 24px !important;
      color: $mainWhiteGrey;
      margin-bottom: 25px;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: $mainPailBlue;
    font-size: 12px;
    .edb-btn {
      height: 30px;
      line-height: 30px;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 12px;
    }
    .popover-base {
      display: inline-block;
      > span {
        cursor: pointer;
      }
    }
    .link-download {
      color: #039be5;
    }
  }

  .ask_icon {
    display: inline-block;
    margin-left: 5px;
    border: 1px solid #DFE3E6;
    border-radius: 50%;
    height: 21px;
    width: 21px;
    color: #768891;
    text-align: center;
    cursor: pointer;
  }
}
