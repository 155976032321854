@import "./variables";

$buttonBorderRadius: 2px;

.employee-list {

  &__flex {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  &__short-info {
    height: 100px;
    width: 100%;
    background-color: $mainDarkBlue;
    margin: 0;
    padding-top: 15px;
  }

  &__outer-controls-container {
    text-align: right;
    padding-right: 20px;
  }

  &__inner-controls-container {
    display: inline-block;
  }

  &__buttons-group-label {
    display: block;
    color: $mainGreyText;
    font-size: 12px;
    letter-spacing: 0.07px;
    text-align: left;
  }

  &__buttons_group-container {
    vertical-align: middle;
  }

  &__button {
    display: inline-block;
    height: 32px;
    padding-top: 3px;
    width: 120px;

    border: 1px solid $mainLightBlue;
    margin-left: -1px;

    text-align: center;
    font-size: 12px;
    color: $mainLightBlue;

    &:nth-of-type(1) {
      border-top-left-radius: $buttonBorderRadius;
      border-bottom-left-radius: $buttonBorderRadius;
    }

    &:nth-of-type(3) {
      border-top-right-radius: $buttonBorderRadius;
      border-bottom-right-radius: $buttonBorderRadius;
    }
  }

  &__employees-info {
    padding-left: 35px;

    span.input-group-btn {
      left: 0px;
      > .btn {
        height: 30px;
      }
    }
  }

  &__wr-selector-container,
  &__datepicker-container {
    display: inline-block;
  }

  &__wr-selector-container {
    margin-left: 20px;
  }

  &__datepicker-container {
    position: relative;
    margin-left: 10px;

    &--moved-up {
      top: -149px;
    }

    .ui-datepicker {
      width: 100px;

      .edb-input {
        width: 90px;
      }
    }

    .input-group {
      margin-bottom: 0;

      input {
        margin: 0;
      }

      .btn {
        position: relative;
        top: 0px;
      }
    }
  }

  &__datepicker-overlay {
    position: absolute;
    top: 24px;
    left: -195px;
    display: inline-block;
    width: 318px;
    height: 30px;
    background: #000;
    opacity: 0.4;
  }

  &__overlay {
    position: relative;
    &_active {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 30px;
      background: #000;
      opacity: 0.4;
      z-index: 999;
    }
  }

  &__label {
    font-size: 12px;
    color: $mainGreyText;
    margin: 0;
  }

  &__table-container {
    position: relative;

    .spinner__centered-wrapper {
      top: 150px;
    }
  }
}

