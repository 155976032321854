@import "./src/styles/variables";
@import "./src/styles/mixins";

.work-record-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $mainSoftBlue;

  &__title {
    margin-bottom: 2px;
    line-height: 32px;
    font-size: 26px;
    font-weight: 700;
    color: #1D2B32;
  }

  &__sub-title {
    margin-bottom: 10px;
    font-size: 14px;
    text-transform: uppercase;
  }

  &__header {
    padding: 32px 24px 0;
  }

  &__body {
    margin-bottom: 10px;
    padding: 0 24px 24px;
    overflow-y: auto;
    @include custom-scroll;

    .card {
      border: none !important;

      .card-body {
        padding: 40px;
        border-radius: 6px;
        box-shadow: 0px 5px 10px rgba(35, 54, 108, 0.07);

        >.card-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;
          line-height: 24px;
          font-size: 18px;
          font-weight: 600;

          .popup-button {
            margin: 0;
            line-height: 18px;
            font-size: 14px;
            font-weight: 400;
            color: #0A88EC;
            cursor: pointer;

            > svg {
              height: 12px;
              width: 13px;
              margin-right: 6px;
              vertical-align: baseline;
            }
          }
          .popup-button:has(svg) {
            color: #039be5;
          }
        }

        .popup-button {
          > svg {
            height: 12px;
            vertical-align: baseline;
          }
        }
        .popup-button:has(svg) {
          color: #039be5;
        }
      }
    }

    .row:last-child {
      .card {
        height: 100%;
      }
    }

    .popup-button {
      cursor: pointer;
    }
  }

  &__footer {
    margin-top: auto !important;
    padding: 24px 20px;
    background-color: $mainPailBlue;
  }

  .activate-wr-button,
  .close-wr-button {
    font-size: 12px;
    line-height: 18px;
    color: #039be5;
    cursor: pointer;
  }
  &__code {
    position: absolute;
    top: 0;
    right: 20px;
    height: 54px;
    padding: 20px 20px 10px;
    background: url('./../../../assets/images/work-record/bookmark.svg') no-repeat center center;
    color: #fff;
    font-size: $mediumFontSizeType1;
    text-align: center;
  }
}
