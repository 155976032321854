@import "./src/styles/variables";

.no-permissions-error {
  background: $mainSkyBlue;
  border-radius: 5px;
  padding: 20px;
  height: 100%;

  .message {
    background: white;
    padding: 20px;
  }
}