@import "~bootstrap/scss/bootstrap";
@import 'react-redux-toastr/src/styles/index';

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600);

@import "grid.scss";
@import "common.scss";
@import "forms.scss";
@import "buttons.scss";

body {
  height: 100%;
}

#root {
  height: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.application {
  position: relative;
  height: 100%;
  min-width: 1024px;
  margin: 0 auto;
  border-right: 1px solid #e1edf1;
  border-left: 1px solid #e1edf1;
  border-radius: 4px;
  background-color: #fff;
  .custom-container {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 1px;
  }
}
@media screen and (max-width: 1024px) {
  .application {
    width: 1024px;
  }
}

.clearfix {
  &::before,
  &::after {
    display: table;
    content: ' ';
  }
  &::after { clear: both; }
}
