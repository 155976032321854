.compliance-table__body {
  .filter-wrapper {
    .filter-input {
      height: 35px;
      width: 100%;
      margin: 7px auto;
      padding: 0 5px;
      border: 1px solid #d2deea;
      border-radius: 0;
      line-height: 35px;
      outline: #039be5;
      background-position: calc(100% - 7px) center;
      background-repeat: no-repeat;
      background-image: url('../../../assets/icons/search.png');
    }
    .filter-select-all {
      margin: 0;
      padding: 5px 0;
      cursor: pointer;
    }
    .filter-checkbox-list {
      max-height: 150px;
      overflow-y: auto;
      scrollbar-width: thin;
      .filter-checkbox-item-wrapper {
        .filter-checkbox-item-label {
          margin: 8px 0;
          line-height: normal;
          .filter-checkbox-item-input {
            margin-right: 6px;
            line-height: normal;
          }
        }
      }
    }
    .filter-button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
    .filter-button {
      margin-top: 15px;
      border: 0;
      outline: none;
      border-radius: 20px;
      padding: 8px 16px;
      line-height: 11px;
      font-size: 11px;
      background-color: #039be5;
      color: #FFFFFF;
    }
  }
}