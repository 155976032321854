@import './src/styles/variables';

.form-cv {
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  .react-datepicker-wrapper + svg {
    top: 5px !important;
  }

  input {
    font-size: 14px !important;
    color: #313942 !important;
    &:not([type='checkbox']) {
      height: 30px;
    }
    &.form-control {
      width: 100% !important;
    }
  }

  textarea {
    font-size: 14px !important;
    color: #313942 !important;
    &::placeholder {
      font-size: 13px !important;
      color: #829199 !important;
      opacity: 1;
    }
    &::-ms-input-placeholder {
      font-size: 13px !important;
      color: #829199 !important;
    }
  }

  .select-field-custom {
    &__control {
      min-height: 30px !important;
    }
    &__indicator {
      padding: 4px !important;
    }
    &__indicator-separator {
      margin-top: 4px !important;
      margin-bottom: 4px !important;
    }
    &__value-container {
      padding: 0 6px 0 10px !important;
      font-size: 14px !important;
    }
  }

  .btn.btn-link {
    font-size: 12px;
    color: $mainLightText;
    box-shadow: none !important;
    text-decoration: none !important;
  }

  .cv-list {
    position: relative;
    padding-top: 60px !important;
    &__title {
      position: absolute;
      top: 0;
      left: -40px;
      right: -40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;
      margin: 0 !important;
      padding: 0 20px;
      font-size: 14px;
      background-color: #fef3e6;
      > span {
        padding-left: 10px;
      }
    }
    &__item {
      margin: 0!important;
      font-size: 14px;
    }
    .edb-btn {
      height: auto;
      line-height: unset !important;
      min-width: 110px;
      width: auto;
      padding: 6px 12px;
      margin-left: 30px;
      font-size: 14px;
    }
  }

  .section-education {
    &__value {
      font-size: 14px;
    }
  }
}
.cv-popover {
  max-width: unset !important;
  width: 600px;
  .popover-header {
    font-size: 14px;
    text-transform: uppercase;
    color: #1d2b32;
    background-color: #d9e6eb !important;
  }
}
