@import "src/styles/variables";

.employee-table-wrapper {
  height: 100%;
  background: $mainSkyBlue;
}

.employee-table-header {
  padding: 4px 10px 4px 10px;
  color: $mainDarkBlue;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .table-settings-wrapper {
    float: right;
  }
}

.table-wrapper {
  overflow: auto;
  scrollbar-width: thin;
  width: calc(100% - 11px);
  margin: 0 10px;
  background-color: $mainSkyBlue;

  table {
    border: none;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin-bottom: 0;

    tbody {
      tr[role="row"] {
        cursor: pointer;
        &.row-blue {
          background: $mainSoftBlue;
          td {
            background: $mainSoftBlue;
          }
        }
        &.row-white {
          background: $mainWhite;
          td {
            background: $mainWhite;
          }
        }
        a {
          text-decoration: none;
          color: $mainLightBlue;
          &:hover {
            color: #23527c;
          }
        }
      }
    }

    tr {
      border: none;
    }

    th {
      position: sticky;
      top: -1px;
      height: 64px;
      font-size: 11px;
      letter-spacing: 0.5px;
      vertical-align: middle !important;
      color: $mainGreyText;
      text-transform: uppercase;
      white-space: nowrap;
      background: $mainSkyDarkBlue;
      &:first-child {
        z-index: 2;
        background: $mainSkyDarkBlue;
      }
      &.filtering {
        background: $mainWhite;
      }
    }

    td {
      height: 51px;
      font-size: 11px;
      color: $mainGreyText;
      vertical-align: middle;
      &:first-child {
        z-index: 1;
      }
    }

    td:first-child, th:first-child {
      position: sticky;
      left: 0;
    }
  }

  .table-sort {
    font-size: 11px;
  }

  .table-filter {
    font-size: 11px;
    .filter-control {
      display: none;
    }
  }

}
