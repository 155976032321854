@import "./src/styles/variables";

.fa-exchange-alt,
.fa-long-arrow-alt-right {
  color: $mainLightBlue;
}
.fa-check-circle {
  color: #39b54a;
}
.fa-clock {
  color: #ffad00;
}

.fa-exchange-alt {
  font-size: 12px;
  cursor: pointer;
}

#popover-attributeFuture {
  .popover-body {
    padding-bottom: 0;

    .attribute-item {
      border-bottom: 1px solid #d9e6eb;
      padding: 20px;
    }
    .attribute-previous-title {
      margin-right: 7px;
    }
    .attribute-future-title {
      margin-left: 7px;
    }
    p {
      margin: 0;
      color: #829099;
      font-size: 12px;
      &.attribute-status {
        margin-top: 10px;
      }
    }
    a {
      text-decoration: none;
      color: $mainLightBlue;
    }
  }
}