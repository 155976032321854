@import "./src/styles/variables";

.turnover-error {
  background: $mainWhite;
  border-radius: 5px;
  padding: 20px 20px 20px 20px;
  margin-left: 20px;
  .header {
    color: $mainGreyText;
    font-size: 12px;
    font-weight: 100;
    letter-spacing: .9px;
    margin-bottom: 45px;
  }
}

.data-wrapper {
  overflow-y: auto;
  .sticky-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding:0 15px;
    z-index: 2;
  }
  .details-control {
    height: 55px;
    position: relative;
    background: #fff;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    .tabs {
      list-style: none;
      padding-left: 20px;
      .tab {
        display: inline-block;
        margin-right: 32px;
        height: 54px;
        font-family: 'Source Sans Pro';
        font-size: 12px;
        line-height: 50px;
        color: $mainWhiteGrey;
        text-transform: uppercase;
        border-bottom-width: 4px;
        border-bottom-style: solid;
        border-bottom-color: $mainWhite;
        &.active {
          color: $mainDarkBlue;
          border-bottom-color: $mainLightBlue;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .filter-field {
      margin-right: 20px;
      padding-top: 3px;
    }
  }

  .export-container {
    margin-right: 15px;
    margin-top: 5px;
    .turnover-markers-wrapper {
      margin-top: 10px;
      margin-left: 20px;
      .marker {
        position: relative;
        display: inline-block;
        margin-right: 25px;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: -20px;
          transform: translateY(-50%);
          width: 12px;
          height: 12px;
          border-radius: 4px;
        }
        &.bad::before{
          background-color: #ff6633;
        }
        &.good::before{
          background-color: #ffce6a;
        }
      }
    }
  }
  .export-wrapper {
    color: $mainLightBlue;
    cursor: pointer;
    margin-right: 30px;
  }
}

