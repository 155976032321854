.checkbox-filter-wrapper {

  .checkbox-list {
    height: 150px;
    overflow: auto;
  }

  .checkbox-filter-input {
    margin-right: 5px;
  }

  .checkbox-filter-label {
    width: 100%;
    white-space: break-spaces;
  }

  .checkbox-filter-select-all {
    display: block;
    margin-bottom: 7px;
    cursor: pointer;
  }
}
