.horizontal-bar-wrapper {
  margin-top: 20px;
  margin-bottom: 30px;
  .horizontal-bar {
    display: inline-block;
    width: 100px;
    height: 12px;
    background-color: #ccd3df;
    margin-top: 8px;
    margin-right: 5px;
    .bar-overlay {
      height: 12px;
      display: block;
      &.good {
        background-color: #ffce6a;
      }
      &.bad {
        background-color: #ff6633;
      }
    }
  }
  .count {
    display: inline-block;
  }
}