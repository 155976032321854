.filter-trigger {
  cursor: pointer;
  padding-left: 5px;
}

.filter-wrapper {
  display: none;
  position: absolute;
  left: 0;
  top: 62px;
  width: auto;
  min-width: 100%;
  padding: 10px 15px 15px 15px;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
  text-transform: none;

  &.active {
    display: block;
    box-shadow: 0 1px 10px -2px rgba(0, 0, 0, 0.2);
  }
}
