@mixin popupHeader {
  .modal-header {
    border: none;
    background: $mainPailBlue;
    .modal-title {
      font-size: 18px;
    }
  }
}

@mixin vertical-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin popup-box-shadow {
  box-shadow: 0 4px 6px 4px rgba(0, 0, 0, 0.3);
}

@mixin disabled {
  opacity: 0.6;
  cursor: not-allowed;
  user-select: none;
}

@mixin custom-scroll {
  scrollbar-width: thin;
  scrollbar-height: thin;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $mainGrey;
  }
}