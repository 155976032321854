@import "./src/styles/variables";

.popover-base {
  display: inline-block;
  margin-left: 3px;
  .popover {
    border-radius: 0;
    .popover-header {
      width: auto;
      padding: 0.6rem 0.75rem;
      background-color: $mainPailBlue;
      font-size: 11px;
      text-transform: uppercase;
    }

    .popover-body {
      font-family: "Proxima Nova Regular";
      font-weight: 400;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $mainLightDarkText;
    }
  }
}