@import './src/styles/variables';

.status {
  padding: 0 7px;
  border-radius: 12px;
  font-size: 12px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: .9px;
  text-transform: uppercase;
  white-space: nowrap;
  &.status-wr {
    font-size: 12px;
    padding: 1px 5px;
  }
  &.status-common {
    color: white;
  }
  &.status-active {
    background: $mainLightGreen;
    color: white;
  }
  &.status-jo-wr {
    background: $mainPurple;
    color: white;
  }
  &.status-jo {
    border: 1px solid $mainLightGreen;
    color: $mainLightGreen;
  }
  &.status-inactive {
    background: gray;
    color: white;
  }
}
