@import "src/styles/variables";

.table-short-info-wrapper {
  padding: 10px 25px;
  background: $mainDarkBlue;
  .grid-wrapper {
    width: 410px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
  .input-wrapper {
    label {
      margin-bottom: 0;
      font-size: 12px;
      color: $mainGreyText;
    }
  }
  .my-team-greeting {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: .3px;
    color: #fff;
    line-height: 24px;
    margin: 25px 0 0 35px;
    display: block;
  }
  .my-team-description {
    font-size: 15px;
    font-weight: 400;
    color: #768891;
    line-height: 24px;
    letter-spacing: .07px;
    text-align: left;
    display: block;
    margin-left: 35px;
  }
  .my-team-team-members-qty {
    color: #fff;
  }
}
