.structure-nav {
  background-color: rgb(242, 242, 242);
  .nav-item {
    list-style: none;
    border: 1px solid #039be5;
    &:first-child {
      border-radius: 3px 0px 0px 3px;
    }
    &:last-child {
      border-radius: 0px 3px 3px 0px;
    }
    > .nav-link {
      line-height: 32px;
      letter-spacing: 0.75px;
      border-radius: 0px;
      font-size: 16px;
      color: #039be5;
      &:hover {
        background-color: transparent;
      }
      &.active {
        color: white;
        background-color: #039be5;
      }
    }
  }
  .font-size--12 {
    font-size: 12px !important;
  }
}