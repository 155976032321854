@import "../../styles/variables";

.custom-datepicker {
  position: relative;
  display: flex;
  .period-title {
    color: #959595;
    margin-right: 10px;
    line-height: 28px;
  }
  .custom-datepicker-outer {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    width: 176px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #ccd3df;
    margin-right: 20px;
    padding-left: 5px;
    outline: none;
    background: url('data:image/svg+xml,<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 2H15V1C15 0.45 14.55 0 14 0C13.45 0 13 0.45 13 1V2H5V1C5 0.45 4.55 0 4 0C3.45 0 3 0.45 3 1V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 17C16 17.55 15.55 18 15 18H3C2.45 18 2 17.55 2 17V7H16V17ZM4 9H6V11H4V9ZM8 9H10V11H8V9ZM12 9H14V11H12V9Z" fill="%23A6B7C2"></path></svg>') #FFFFFF no-repeat right 6px center;
    background-size: 18px;
    span {
      width: 100%;
    }
    .fa-calendar {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #959595;
    }
  }
  .custom-datepicker-inner {
    position: absolute;
    bottom: -240px;
    width: 260px;
    height: 240px;
    padding: 14px 0px;
    border-radius: 4px;
    z-index: 10;
    box-shadow: 0 3px 9px 0 rgba(139, 148, 172, 0.25);
    background-color: #ffffff;
    .custom-datepicker-title {
      padding: 0 24px 0 16px;
    }
    .custom-datepicker-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #959595;
      padding: 15px 24px 0 16px;
      .edb-select {
        .selectize-input {
          color: #959595;
          &::after {
            right: 10px;
          }
        }
        .selectize-dropdown {
          color: #959595;
        }
      }
      .custom-datepicker-label {
        flex-grow: 0.5;
        margin-left: 0;
      }
      & > * {
        flex: 1;
        margin-left: 8px;
      }
      .datepicker-flex2 {
        flex: 1.5
      }
    }
    .custom-datepicker-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      padding: 0 24px 0 16px;
      .btn-cancel {
        border: 0;
        background-color: transparent;
        color: #2085ff;
        font-weight: bold;
        &:hover {
          color: lighten(#2085ff, 5%);
        }
      }
      .btn-select {
        border: 0;
        border-radius: 4px;
        background-color: #2085ff;
        color: #fff;
        padding: 5px 16px;
        margin-left: 20px;
        font-weight: bold;
        &:hover {
          background-color: lighten(#2085ff, 5%);
        }
      }
    }
    .custom-datepicker-filters {
      position: absolute;
      display: flex;
      justify-content: space-around;
      align-items: center;
      bottom: 0;
      width: 100%;
      height: 48px;
      margin-top: 20px;
      background-color: rgba(204, 211, 223, 0.2);
      .range-filter {
        color: #2085ff;
        padding-bottom: 5px;
        cursor: pointer;
        &.active {
          border-bottom: 1px solid #2085ff;
        }
      }
    }
  }
}