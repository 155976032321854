.row-negative-m {
  margin-left: -10px;
  margin-right: -10px;
}

.col-no-padding {
  padding: 0;
}

.col-no-right-padding {
  padding-right: 0;
}

.col-no-left-padding {
  padding-left: 0;
}

.col-padding-in-row-left{
  padding: 0 5px 0 15px;
}

.col-padding-in-row-right{
  padding: 0 15px 0 5px;
}

.col-sp-10 {
  padding: 0 10px;
}

.col-sp-10-5 {
  padding: 0 5px 0 10px;
}

.col-sp-5-5 {
  padding: 0 5px;
}

.col-sp-5-10 {
  padding: 0 10px 0 5px;
}

@media (min-width: 1200px) {
  .col-xl-sp-10 {
    padding: 0 10px;
  }
  .col-xl-pr-10 {
    padding: 0 10px 0 0;
  }
  .col-xl-pl-10 {
    padding: 0 0 0 10px;
  }
  .col-xl-sp-10-5 {
    padding: 0 5px 0 10px;
  }
  .col-xl-sp-5-10 {
    padding: 0 10px 0 5px;
  }
}

@media (min-width: 988px) and (max-width: 1199px) {
  .col-lg-sp-10-5 {
    padding: 0 5px 0 10px;
  }
  .col-lg-sp-5-5 {
    padding: 0 5px;
  }
  .col-lg-sp-5-10 {
    padding: 0 10px 0 5px;
  }
}

@media (min-width: 769px) and (max-width: 987px) {

}

@media (min-width: 480px) and (max-width: 768px) {

}
