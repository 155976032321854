@import "src/styles/variables";

.table-settings-wrapper {
  font-size: 15px;
  color: $mainLightBlue;
  .popup-trigger {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    padding: 6px 10px;

    &.active {
      background: white;
    }
  }

  .export {
    line-height: normal;
    top: 0;
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: 400;
    .table-settings__control-icon {
      padding: 0 3px;
      vertical-align: middle;
    }
    .export-content {
      direction: ltr;
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      padding: 12px 16px;
      z-index: 1;
    }
  }

  .export:hover .export-content {
    display: block;
  }

  #allEmployeeTableSettingsPopover {
    max-width: 320px;
    border: none;
    font-size: 15px;
    font-weight: 700;
    padding-top: 20px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 5px 5px 3px rgba(0, 0, 0, 0.12);
    background: #fff;
    .arrow {
      display: none;
    }
    .popover-body {
      padding: 0;
      color: $mainGreyText;
      border-bottom: 1px solid #d7d1ec;
      margin-bottom: 17px;
      .nav {
        text-transform: uppercase;
        .nav-item {
          width: 50%;
          text-align: center;
          outline: none;
          border: none;
          font-size: 13px;
          color: $mainGreyText;
          &.active {
            box-shadow: 0 -2px 0 0 $mainLightBlue inset;
          }
        }
      }
      .tab-content {
        width: 320px;
        height: 210px;
        overflow-y: auto;
        padding: 10px $checkboxLeftPadding;
        label {
          margin-bottom: 1em;
        }
      }
    }

    .table-settings {
      &__reset-button {
        display: inline-block;
        margin: 0 (100% - $searchFieldWidth)/2;
        font-size: 13px;
        color: $mainLightBlue;
        cursor: pointer;
      }

      &__bottom {
        display: inline-flex;
        margin-bottom: 15px;

        .edb-big-button {
          display: inline-block;
          background-color: $mainLightBlue;
          width: 80px;
          max-width: none;
          height: $bigButtonHeight;
          border-radius: 20px;
          border: 0;
          margin-right: 5px;
          color: $mainWhiteText;
          font-size: $mediumFontSize;
          font-weight: $mediumFontWeight;
          text-align: center;
          outline: none;
          line-height: $bigButtonHeight - $bigButtonBorderWidth*2 - 3;

          &:hover {
            background-color: #05aae9;
          }

          &:active {
            background-color: #029eda;
          }

          &:focus {
            outline: none;
          }

          &--nobackground {
            background-color: #fff;
            color: $mainLightBlue;

            &:hover {
              background-color: #29b6f6;
              color: #fff;
            }

            &:active {
              background-color: #0277bd;
            }
          }
        }
      }

      &__search-field {
        border: 1px solid #d7d1ec;
        width: $searchFieldWidth;
        font-size: 14px;
        margin: 0 (100% - $searchFieldWidth)/2;
        margin-bottom: 20px;
        margin-top: 15px;
        padding: 7px 5px;
      }

      &__select-all {
        margin-top: 7px;
        padding-left: $checkboxLeftPadding;

        .checkbox {
          margin-right: 5px;
        }
      }

      &__buttons-group {
        padding-left: 30px;

        button {
          width: 90px;
        }
      }
    }
  }

  .table-settings-button {
    display: inline-block;
  }
}
