@import "./src/styles/variables";
@import "./src/styles/mixins";

header {
  height: 60px;
  .header-wrapper {
    display: flex;
    flex-direction: row;
    padding: 0 16px;
    .header-logo {
      display: flex;
      align-items: center;
      height: 60px;
      width: 16%;
      .logo {
        display: block;
        width: 100%;
        height: 46px;
        background: url(./../../assets/images/logo.png) no-repeat left center;
        background-size: contain;
      }
      @media (max-width: 767px) {
        align-items: flex-start;
      }
    }

  a.disabled {
    pointer-events: none;
    cursor: default;
    color: #ccc !important;
  }
    .header-navigation {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      margin-left: 75px;
      > .nav {
        flex-wrap: nowrap !important;
        > .nav-item {
          > .nav-link {
            position: relative;
            padding: 0 14px 0 0;
            color: $secondaryGreyText;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: .9px;
            text-transform: uppercase;
            text-decoration: none;
            &.active {
              color: $secondaryGreyText;
              &:after {
                content: "";
                position: absolute;
                bottom: -20px;
                right: 7px;
                display: block;
                height: 2px;
                width: 100%;
                background-color: $mainLightBlue;
              }
            }
            &:hover {
              color: $activeLinkColor;
            }
          }
        }
      }
      a.edb-btn {
        height: 30px;
        line-height: 28px;
        font-size: 12px;
        margin-left: 15px;
        &:hover {
          text-decoration: none;
          color: #fff;
        }
      }
    }
    .header-links {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;
      > .nav {
        flex-wrap: nowrap !important;
        .nav-item {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 62px;
          width: 50px;
          &--avatar {
            img {
              width: inherit;
              height: inherit;
              border-radius: 50%;
              object-fit: cover;
            }
            .dropdown-toggle {
              width: 36px;
              height: 36px;
              padding: 0;
              &::after {
                display: none !important;
              }
            }
            .dropdown-menu {
              top: 10px !important;
              right: -8px !important;
              width: 150px;
              background-color: #fff;
              font-size: 14px;
              padding-top: 0;
              padding-bottom: 0;
              @include popup-box-shadow;
              .dropdown-item {
                padding: 10px 15px;
                color: #8aa8bd !important;
                border-bottom: 1px solid #e6e8ea;
                box-shadow: inset 0 1px 0 #fff;
                &:hover {
                  background-color: #f3f8f8;
                  color: #23527c !important;
                }
              }
            }
          }

          &.dropdown.show {
            background-color: $mainSoftBlue;
            &::before {
              content: "";
              display: inline-block;
              width: 100%;
              height: 4px;
              position: absolute;
              top: 0;
              background: $mainDarkBlue;
            }
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
