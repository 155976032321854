@import "./src/styles/variables";

.edb-hr-dashboard-wrapper {
  margin-left: 160px;
  height: 100%;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif;
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .filter-group {
    display: flex;
    flex-flow: wrap;
    .filter-field {
      margin-right: 5px;
    }
    .export-link {
      margin-left: auto;
      line-height: 60px;
      font-size: 14px;
      color: #039be5;
    }
  }

  .table-settings__control-link {
    margin-right: 30px;
    cursor: pointer;
  }

  .table-settings__control-icon {
    padding: 0 3px;
    vertical-align: middle;
  }
}
