@import "./src/styles/variables";
@import "./src/styles/employee-list";

.headcount-csv {
  height: 100% !important;
  padding: 30px 32px 10px 32px;
  font-size: 14px;
  color: #039be5;
  .custom-label {
    color: #959595;
    margin-right: 10px;
    margin-top: 5px;
  }
  .buttons-list {
    margin-right: 20px;
    .button {
      display: inline-block;
      font-family: 'Source Sans Pro';
      font-size: 12px;
      line-height: 28px;
      text-align: center;
      height: 30px;
      width: 80px;
      border: 1px solid #768691;
      margin-left: -1px;
      color: #768691;
      text-transform: capitalize;
      &.btn-small {
        width: 55px;
      }
      &.active {
        color: $mainWhite;
        background: #768691;
      }

      &:hover {
        cursor: pointer;
      }

      &:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      &:last-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
    @media (max-width: 1200px) {
      margin-bottom: 10px;
    }
  }
  .fa-calendar-alt {
    top: 6px;
    right: 6px;
  }
}

