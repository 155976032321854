@import "./src/styles/variables";

.input-filter-input {
  height: 35px;
  line-height: 35px;
  border-radius: 0;
  margin: 7px auto;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid $mainSmokeBlue;
  outline: #039be5;
  background-position: calc(100% - 7px) center;
  background-repeat: no-repeat;
  background-image: url('../../../assets/icons/search.png');
  &::-webkit-input-placeholder {
    color: rgba(118, 136, 145, 0.51);
  }
  &::-moz-placeholder {
    color: rgb(118, 136, 145);
  }
  &:-ms-input-placeholder {
    color: rgb(118, 136, 145);
  }
  &:-moz-placeholder {
    color: rgb(118, 136, 145);
  }

  &:hover {
    border-color: #a6b8c0;
  }
}
