@import "./src/styles/variables";

.leavers-detail-item {
  padding: 20px;
  .leavers-detail-item-container {
    background: $mainSoftBlue;
    border-radius: 5px;
    padding: 29px 32px 32px 32px;
    margin-bottom: 20px;
    .multiple-chart-wrapper {
      width: 100%;
      padding-top: 30px;
      &.long {
        overflow-x: auto;
      }
      .chart-bar-wrapper {
        width: 90%;
        height: 140px;
      }
    }
    .no-data-wrapper {
      padding: 20px;
    }
    .header-title {
      font-size: 22px;
      font-weight: 600;
    }
    .title-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      & > span {
        display: inline-block;
        font-size: 15px;
        &.marker {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
    .reasons-wrapper {
      display: flex;
      align-items: center;
      .dash {
        margin: 0 5px 0 5px;
      }
    }
  }
}