@import "src/styles/variables";

.my-team-button-container {
  text-align: right;
  padding-right: 15px;
  z-index: 4;
  .my-team-button {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #039be5;
    color: #768891;
    font-size: 12px;
    font-weight: 600;
    padding: 6px 18px;
    margin-top: 25px;
    margin-left: -1px;
    margin-right: -1px;
    &.active {
      color: #fff!important;
      background-color: #039be5!important;
    }
    &:nth-of-type(1) {
      margin-left: 20px;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }
}
