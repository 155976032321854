@import './src/styles/variables';

body, html {
  height: 100%;
}

.login-page {
  height: 100vh !important;
  width: 100%;
  background-color: #1e0640;
  &--logo {
    margin-top: 56px;
    margin-right: 98px;
  }
  .form-signin {
    background: white;
    margin: auto;
    width: 368px;
    padding: 52px 28px 40px;
    border-radius: 8px;
    > h1 {
      margin-top: 0;
      margin-left: 42px;
      margin-bottom: 35px;
      font-size: 24px;
      font-family: 'Proxima Nova Bold';
    }
    button {
      margin-top: 44px;
      height: 48px;
      font-size: 16px;
      font-family: 'Proxima Nova Bold';

      &:hover,
      &:focus {
        color: white;
      }
      &.btn-dark {
        background: $color-main-theme--purple-medium;
      }
    }
  }
}

@media screen and (max-width: 991.98px) {
  .login-page--logo {
    margin-bottom: 100px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
}

@media (max-width: 767.98px) {
  .form-signin {
    margin-bottom: 140px !important;
    width: 338px !important;
  }
}

@media (max-width: 390px) {
  .form-signin {
    width: 280px !important;
  }
}