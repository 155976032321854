@import "./src/styles/variables";

.edb-compliance-wrapper {
  background: $mainSkyBlue;
  padding-bottom: 10px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;

  .compliance-navigation {
    background: $mainDarkBlue;
    margin: 0;
    padding: 0 10px;
    list-style: none;
    li {
      display: block;
      float: left;
      .nav-button {
        display: block;
        padding: 10px;
        color: $mainWhiteText;
        cursor: pointer;
        &--active {
          border-bottom: 4px solid $mainLightBlue;
        }
      }

    }
  }

  .compliance-content {
    padding: 5px;
    background: $mainSoftBlue;
  }

  .compliance-view {
    background: $mainSkyBlue;
  }

  .clearfloat {
    &:before, &:after {
      content: " ";
      display: table;
    }
    &:after {
      clear: both;
    }
  }

  .compliance-filter-form {
    padding: 10px;
    .filter-group {
      display: flex;
      align-items: flex-end;
      .export-link {
        .table-settings__control-link {
          margin-right: 0;
        }
      }
    }
    .clear-button {
      color: #000000a1;
    }
    .control-buttons {
      button {
        margin-top: 29px;
      }
    }
    .rbt {
      .rbt-input-main {
        width: 160px;
        height: 32px;
      }
      .dropdown-menu {
        .dropdown-item {
          font-size: 13px;
        }
      }
    }
    .edb-label {
      font-size: 12px;
      color: #829199;
      font-weight: 400;
      display: block;
      text-align: left;
    }

    .edb-select {
      height: $inputHeight;
      margin: 8px 0;
      &--small {
        width: $extraSmallInputWidth;
      }

      &--small2 {
        width: $smallInputWidth;
      }

      &--medium {
        width: $mediumInputWidth;
      }

      &--large {
        width: $standartInputWidth;
      }

      .selectize-input {
        min-height: 100%;
        background-color: #fff !important;
        background-image: none !important;
        box-shadow: none !important;
        border-color: $mainSmokeBlue !important;
        padding: 6px;
        display: flex;
        align-items: center;
        &:after {
          border-color: #a7b8c0 transparent transparent transparent !important;
        }
      }

      .selectize-dropdown, .selectize-input, .selectize-input input {
        line-height: $mediumFontSize;
      }

      &--search {
        .selectize-input {
          &:after {
            content: none !important;
          }

          padding-right: 25px;
          //background-image: url("/assets/images/elements/search.png") !important;
          background-position: calc(100% - 12px) center !important;
          background-repeat: no-repeat !important;
        }
      }
    }

    .selectize-control {
      position: relative;
    }

    .edb-big-button {
      display: inline-block;
      background-color: $mainLightBlue;
      width: 110px;
      max-width: none;
      height: $bigButtonHeight;
      border-radius: 20px;
      border: 0;
      margin-right: 5px;
      color: $mainWhiteText;
      font-size: $mediumFontSize;
      font-weight: $mediumFontWeight;
      text-align: center;
      outline: none;
      line-height: $bigButtonHeight - $bigButtonBorderWidth*2 - 3;

      &:hover {
        background-color: #05aae9;
      }

      &:active {
        background-color: #029eda;
      }

      &:focus {
        outline: none;
      }

      &--secondary {
        color: #04baff;
        border: $bigButtonBorderWidth solid #04baff;
        background-color: #ffffff;

        &:hover {
          background-color: #f3fcff;
        }

        &:active {
          background-color: #e0f3fa;
        }
      }

      &--tertiary {
        color: $mainLightBlue;
        border: $bigButtonBorderWidth solid $mainLightBlue;
        background-color: $mainWhite;

        &:hover {
          color: #84949c;
          background-color: #f1f6f9;
        }

        &:active {
          color: #84949c;
          background-color: #e6eef3;
        }
      }
    }
  }
}

.compliance-table {
  &__wrapper {
    padding: 10px;
  }
  &__header {
  }
  &__export {
    background: #e9f0f3;
    text-align: right;
    padding: 6px 12px 16px;
  }
  &__export-link {
    cursor: pointer;
    > svg {
      margin-right: 5px;
    }
    > span {
      color: #039be5;
    }
  }
  &__body {
    table {
      thead, tfoot {
        tr {
          background: $mainSkyDarkBlue;
          color: $mainGreyText;
          font-size: 11px;
          letter-spacing: .483px;
          text-transform: uppercase;
        }
      }
      thead {
        tr {
          th {
            position: relative;
            height: 71px;
            padding: 10px;
            &.filtering {
              background: #FFFFFF;
            }
            > .table-title {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              > span {
                text-align: center;
              }
              .table-title__with-sorting {
                margin: 0 !important;
                cursor: pointer;
              }
              .table-sort,
              .table-filter {
                padding-left: 3px;
                padding-right: 3px;
                cursor: pointer;
              }
            }
            .table-filter {
              .filter-trigger {
                padding: 0;
              }
              .filter-wrapper {
                position: absolute;
              }
            }
          }
        }
      }
      tbody {
        tr {
          &:nth-child(even) {
            background: $mainSoftBlue;
          }
          &:nth-child(odd) {
            background: $mainWhite;
          }
          &.expired {
            background: #ff554826;
          }
          > td {
            height: 51px;
            padding: 10px;
            text-align: center;
            font-size: 12px;
            white-space: nowrap;
            a,
            .document-name-trigger {
              cursor: pointer;
              color: $mainLightBlue;
            }
          }
        }
      }
      tfoot {
        tr {
          td {
            padding: 5px;
            height: 51px;
            &:last-child {
              text-align: right;

            }
          }
        }
        .nav-next,
        .nav-previous {
          margin-left: 10px;
          padding: 3px 10px;
          border-width: 0;
          border-radius: 15px;
          background: $mainLightBlue;
          color: $mainWhite;
          &:disabled {
            background-color: #85a5b8;
          }
        }
      }
    }
  }
}
