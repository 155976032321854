@import "./src/styles/variables";

.work-record-compliance {
  height: 100%;
  padding: 10px;
  position: relative;
  background-color: #ebf2f5;
}

.compliance-content {
  width: 100%;

  .document-name {
    cursor: pointer;
    color: $mainLightText;
  }

  .nav-next, .nav-previous {
    background: $mainLightBlue;
    padding: 3px 10px;
    border-width: 0;
    border-radius: 15px;
    color: $mainWhite;
    text-transform: uppercase;
    &:hover {
      cursor: pointer;
    }
  }
}

.compliance-filter-form, .compliance-list {
  padding: 10px;
}

.compliance-list table {
  width: 100%;
}

.compliance-list table tfoot tr, .compliance-list table thead tr {
  background: #d7e4e9;
  color: #768891;
  font-size: 11px;
  letter-spacing: .483px;
  text-transform: uppercase;
}

.compliance-list table thead tr th {
  height: 71px;
  text-align: center;
}

.compliance-list table tbody tr td {
  height: 51px;
  padding: 10px;
  text-align: center;
  font-size: 12px;
}

.compliance-list table tfoot tr td {
  padding: 5px;
  height: 51px;
}

.compliance-list table tbody tr:nth-child(odd) {
  background: #fff;
}

.compliance-list table tbody tr.expired {
  background: #ff554826;
}