.compliance-table__filters {
  background: #FFFFFF;
  padding: 10px 20px;
  .filter-header {
    padding: 5px 2px;
    font-size: 13px;
    line-height: 13px;
    .filter-title {
      margin-right: 20px;
      font-weight: 700;
      color: #768891;
    }
    .filter-button-remove-all {
      margin-right: 20px;
      font-weight: 700;
      color: #039be5;
      cursor: pointer;
    }
  }

  .filter-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    padding: 5px 2px;
    .filter-group-title {
      margin-right: 3px;
      font-size: 13px;
      line-height: 13px;
      font-weight: 700;
      color: #768891;
    }
    .filter-value {
      position: relative;
      margin: 0 2px;
      padding: 2px 20px 2px 10px;
      border: 1px solid rgba(118,136,145,.3);
      border-radius: 12px;
      font-size: 12px;
      color: rgba(118,136,145,.85);
      user-select: none;
      > svg {
        position: absolute;
        top: 5px;
        right: 7px;
        cursor: pointer;
      }
    }
    .filter-button-remove-by-group {
      padding: 2px 10px;
      border: 1px solid rgba(118,136,145,.3);
      border-radius: 12px;
      font-size: 12px;
      color: rgba(118,136,145,.85);
      user-select: none;
      cursor: pointer;
      > svg {
        cursor: pointer;
      }
    }
  }
}